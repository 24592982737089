/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addReservation,
  updateReservation,
} from "../../../actions/reservationAction";
import moment from "moment";
// import { clientSearchFilter } from "../../../actions/kpiActions";
// import { getLocationsByUserId } from "../../../actions/locationActions";
// import mapboxgl from "mapbox-gl";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchedByMap from "../Map/SearchedByMap";
import { notifyFailure } from "../../toast";
import Select from "react-select";

// import AdminBookingAddDropPick from "./AdminBookingAddDropPick";
// import { SERVERADDRESS } from "../../../constants";
// import axios from "axios";
import { editPassenger, getAllClients } from "../../../actions/userActions";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import NotAllowed from "../../NotAllowed";
import AdminBookingAddClientModal from "../adminBookingComponents/AdminBookingAddClientModal";
import AdminBookingReturnTrip from "../adminBookingComponents/AdminBookingReturnTrip";
import AdminBookingPriceDetailModal from "../adminBookingComponents/AdminBookingPriceDetailModal";
import AdminSettingVehicleAddNewServiceNameModal from "../adminSettingComponents/AdminSettingVehicleAddNewServiceNameModal";
import AddStudentModal from "../../../pages/adminPages/AdminAddRoutePage/AddStudentModal";

const AdminEditDispatching = () => {
  const directionsService = new window.google.maps.DirectionsService();
  // mapboxgl.accessToken =
  //   "pk.eyJ1IjoiaWNvbmljdGVrIiwiYSI6ImNsZW9oZXlsOTAyZmQzcGw4ejA2Zzk2bmYifQ.StmuWRfUB_3kU8yI5fISxQ";
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;
  const service = useSelector((state) => state.service);
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const students = useSelector((state) => state.students);
  const availableDrivers = useSelector(
    (state) => state.users.allDriversWithoutPagination
  );
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;

  const params = useParams();
  const [mil, setMil] = useState(0);

  const [fareDetails, setFareDetails] = useState();

  let [amount, setAmount] = useState(0);
  let [willCall, setWillCall] = useState(false);

  let navigate = useNavigate();

  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    // if (useHardCodedFares === false && useDirectFare === false) {
    //   calculateFareFrontEnd();
    // }
  };

  const [isSpecialNumber, setIsSpecialNumber] = useState(false);
  const handleCheckboxChange = () => {
    setIsSpecialNumber(!isSpecialNumber);
  };
  // const handleCheckboxChange = () => {
  //   setIsSpecialNumber(!isSpecialNumber);
  //   // Clear the other input value when switching
  //   if (!isSpecialNumber) {
  //     setValues((prevValues) => ({ ...prevValues, loadNumber: '' }));
  //   } else {
  //     setValues((prevValues) => ({ ...prevValues, specialNumber: '' }));
  //   }
  // };

  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const copyParam = queryParams.get("copy");
  const returnTripParam = queryParams.get("returnTrip");
  const returnParam = queryParams.get("return");
  // console.log(copyParam, "copyParam");

  const [showDropDown, setshowDropDown] = useState(false);
  const [driverIdForEdit, setDriverIdForEdit] = useState("");
  const [otherValues, setOtherValues] = useState({
    companyId: "",
    customerId: "",
  });
  const apiFunc = async () => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationById`,
      { _id: params.id }
    );

    if (data.specialNumber) {
      setIsSpecialNumber(true);
    }

    setOtherValues({
      companyId: data.companyId,
      customerId: data.customerId.id,
    });
    const sNames = service?.service.find((x) => x.id === data.type.id);
    setServiceNames(sNames.serviceName);
    // console.log(data, "datadata")
    setHardCodedFares({
      ...hardCodedFares,
      distanceFromBaseLocation: data?.fareAndDistance?.distanceFromBaseLocation,
      distanceFromPickToDropOff:
        data?.fareAndDistance?.distanceFromPickToDropOff,
      apiDistanceFromPickToDropOff:
        data?.fareAndDistance?.apiDistanceFromPickToDropOff,
      precalculatedMiles: data?.fareAndDistance?.precalculatedMiles,
      usePrecalculatedMiles: data?.fareAndDistance?.usePrecalculatedMiles,
      totalMileage: data?.fareAndDistance?.totalMileage,
      baseFare: data?.fareAndDistance?.baseFare,
      flatBaseFare: data?.fareAndDistance?.baseFare,
      baseFareStructure: {
        values: {
          flatBaseFare: data?.fareAndDistance?.baseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadHeadThreshold: data?.fareAndDistance?.fare?.deadHeadThreshold
        ? data?.fareAndDistance?.fare?.deadHeadThreshold
        : 0,
      deadheadFare: data?.fareAndDistance?.deadheadFare,
      baseFarePerMile: data?.fareAndDistance?.deadheadFareStructure?.values
        ?.baseFarePerMile
        ? data?.fareAndDistance?.deadheadFareStructure?.values?.baseFarePerMile
        : 0,
      distanceFromBaseLocation: data?.fareAndDistance?.distanceFromBaseLocation,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: data?.fareAndDistance?.baseFarePerMile,
          distanceFromBaseLocation:
            data?.fareAndDistance?.distanceFromBaseLocation,
        },
        valuesFormula:
          data?.fareAndDistance?.deadheadFareStructure?.valuesFormula,
      },
      farePerMile:
        data?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
      distanceFromPickToDropOff:
        data?.fareAndDistance?.distanceFromPickToDropOff,
      rideFare: data?.fareAndDistance?.rideFare,
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff:
            data?.fareAndDistance?.distanceFromPickToDropOff,
          farePerMile: data?.fareAndDistance?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: data?.fareAndDistance?.offHourFee,
      amount: data?.fareAndDistance?.amount,
      amountStructure: {
        values: {
          rideFare: data?.fareAndDistance?.rideFare,
          // offHourFee: data?.fareAndDistance?.offHourFee,
          baseFare: data?.fareAndDistance?.flatBaseFare,
          deadheadFare: data?.fareAndDistance?.deadheadFare,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: data?.fareAndDistance?.driverPercentage,
      driverAmount: data?.fareAndDistance?.driverAmount,
      companyAmount: data?.fareAndDistance?.companyAmount,
    });
    // console.log("fareDetails1");
    setFareDetails({
      ...fareDetails,
      distanceFromBaseLocation: data?.fareAndDistance?.distanceFromBaseLocation,
      distanceFromPickToDropOff:
        data?.fareAndDistance?.distanceFromPickToDropOff,
      apiDistanceFromPickToDropOff:
        data?.fareAndDistance?.apiDistanceFromPickToDropOff,
      precalculatedMiles: data?.fareAndDistance?.precalculatedMiles,
      usePrecalculatedMiles: data?.fareAndDistance?.usePrecalculatedMiles,
      totalMileage: data?.fareAndDistance?.totalMileage,
      baseFare: data?.fareAndDistance?.baseFare,
      flatBaseFare: data?.fareAndDistance?.baseFare,
      baseFareStructure: {
        values: {
          flatBaseFare: data?.fareAndDistance?.baseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadHeadThreshold: data?.fareAndDistance?.fare?.deadHeadThreshold
        ? data?.fareAndDistance?.fare?.deadHeadThreshold
        : 0,
      deadheadFare: data?.fareAndDistance?.deadheadFare,
      baseFarePerMile:
        data?.fareAndDistance?.deadheadFareStructure?.values?.baseFarePerMile,
      distanceFromBaseLocation: data?.fareAndDistance?.distanceFromBaseLocation,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: data?.fareAndDistance?.baseFarePerMile,
          distanceFromBaseLocation:
            data?.fareAndDistance?.distanceFromBaseLocation,
        },
        valuesFormula:
          data?.fareAndDistance?.deadheadFareStructure?.valuesFormula,
      },
      farePerMile:
        data?.fareAndDistance?.rideFareStructure?.values?.farePerMile,
      distanceFromPickToDropOff:
        data?.fareAndDistance?.distanceFromPickToDropOff,
      rideFare: data?.fareAndDistance?.rideFare,
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff:
            data?.fareAndDistance?.distanceFromPickToDropOff,
          farePerMile: data?.fareAndDistance?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: data?.fareAndDistance?.offHourFee,
      amount: data?.fareAndDistance?.amount,
      amountStructure: {
        values: {
          rideFare: data?.fareAndDistance?.rideFare,
          // offHourFee: data?.fareAndDistance?.offHourFee,
          baseFare: data?.fareAndDistance?.flatBaseFare,
          deadheadFare: data?.fareAndDistance?.deadheadFare,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: data?.fareAndDistance?.driverPercentage,
      driverAmount: data?.fareAndDistance?.driverAmount,
      companyAmount: data?.fareAndDistance?.companyAmount,
    });
    setValues({
      ...data,
      fullName: data.serviceName ? data.serviceName : "",
      driverId: data.driverId ? data.driverId : "",
      uniqueOrderId: data?.uniqueOrderId
        ? data.uniqueOrderId
        : "companyId:" + data.companyId + "orderId" + data.orderId,
      loadNumber: data.specialNumber
        ? data.specialNumber
        : copyParam || returnTripParam
        ? ""
        : data.loadNumber,
      // ...(data.specialNumber && {
      //   specialNumber: data.specialNumber,
      // }),
    });
    setDriverIdForEdit(data.driverId ? data.driverId : "");
    if (!returnTripParam) {
      setStopArr(data.rideHistory);
    } else {
      setStopArr(data.rideHistory.slice().reverse());
    }
    if (data?.customerId?.externalCompanyId) {
      setDetails({
        isCompany: true,
        externalCompanyId: data?.customerId?.externalCompanyId?.id,
      });
    } else {
      setDetails({
        isCompany: false,
      });
    }
    setEta(data.fareAndDistance.estimatedTime);
    setAmount(data.totalAmount);
    setMil(data.totalMileage);
  };
  useEffect(() => {
    apiFunc();
  }, []);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };

  const [showPickLoc, setShowPicLoc] = useState(false);
  const openPickLoc = () => {
    setShowPicLoc((showPickLoc) => !showPickLoc);
  };
  const [showDropLoc, setDropLoc] = useState(false);
  const openDropLoc = () => {
    setDropLoc((showDropLoc) => !showDropLoc);
    // console.log(showDropLoc);
  };
  const [returnTrip, setReturnTrip] = useState(false);
  const [newArrForRT, setNewArrForRT] = useState([]);
  let abc = [];
  const onClickReturnTrip = () => {
    // console.log(stopArr[0], stopArr[1], "newArrForRTnewArrForRT abaa");
    setReturnTrip((returnTrip) => !returnTrip);
    stopArr.forEach((e, i) => {
      abc.push(stopArr[stopArr.length - 1 - i]);
    });
    setReturnStopArr(abc);
    // console.log(abc, "abcabcabcabcabcabc");
  };
  const onClickCloseReturnTrip = () => {
    setReturnTrip((returnTrip) => !returnTrip);
    setWillCall(false);
    setValues({
      ...values,
      willCall: false,
    });
  };

  const [values, setValues] = useState({
    name: "",
    customerId: "",
    type: "",
    bookingDate: "",
    // seats: "1",
    willCall: false,
    needAttended: false,
    numberOfWheelchairs: "1",
    amSeats: "0",
    needWheel: false,
    needAssigned: false,
    pickUpAddress: "",
    pickUpDate: "",
    pickUpTime: "",
    dropOffAddress: "",
    dropOffDate: "",
    dropOffTime: "",
    dateForReservation: "",
    driverNotes: "",
    dispatchNotes: "",
    distanceFromBaseLocation: "",
    distanceFromPickToDropOff: "",
    totalMileage: "",
    // loadNumber: '',
    // clientIdExternal: '',
    fareAndDistance: "",
    rideHistory: [],
    facility: "",
  });
  // console.log("values", values);

  const handleChange = (fieldName) => (event) => {
    if (fieldName === "type") {
      const sNames = service?.service.find((x) => x.id === event.target.value);
      setValues({
        ...values,
        [fieldName]: sNames,
      });
      setServiceNames(sNames.serviceName);
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
    // yahan se new logic
    if (fieldName === "type") {
      setStopArrUseApi(true);
    }
    // yahan takh new logic
  };
  const onClickType = () => {
    setTimeout(() => {
      recalculate();
    }, 5000);
  };
  // console.log(values);
  const dispatch = useDispatch();
  const navigateDispatching = () => {
    // navigate("/requested-orders");
    navigate("/" + returnParam);
    socketInstance?.emit("send-reservation-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      reservationId: values._id,
      role: "admin",
    });
  };
  const [reservationId, setReservationId] = useState({ id: "" });
  const [details, setDetails] = useState({
    isCompany: "",
    externalCompanyId: "",
  });
  const reservationData = {
    companyId: auth.user.companyId.id,
    customerId: values.customerId,
    type: values.type,
    bookingDate: moment().format("YYYY-MM-DD"),
    seats: values.seats,
    willCall: values.willCall,
    needAttended: values.needAttended,
    numberOfWheelchairs: values.numberOfWheelchairs,
    amSeats: values.amSeats,
    needWheel: values.needWheel,
    needAssigned: values.needAssigned,
    pickUpAddress: values.pickUpAddress,
    pickUpDate: values.pickUpDate,
    pickUpTime: values.pickUpTime,
    dropOffAddress: values.dropOffAddress,
    dropOffDate: values.dropOffDate,
    dropOffTime: values.dropOffTime,
    dateForReservation: values.dateForReservation,
    driverNotes: values.driverNotes,
    dispatchNotes: values.dispatchNotes,
    distanceFromBaseLocation: fareDetails?.distanceFromBaseLocation,
    distanceFromPickToDropOff: fareDetails?.distanceFromPickToDropOff,
    totalMileage: mil,
    totalAmount: amount,
    // totalAmount: fareDetails?.driverAmount,
    rideHistory: values.rideHistory,
    loadNumber: values.loadNumber,
    loadNumberIdentifier:
      "cus:" + values.customerId + "-ln:" + values.loadNumber,
    clientIdExternal: values.clientIdExternal,
    isCompany: details.isCompany === true ? true : false,
    fareAndDistance: fareDetails,
    externalCompanyId: details.externalCompanyId
      ? details.externalCompanyId
      : "",
  };

  let newArr = [];
  let newArrReturn = [];
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let distanceFromBaseLocationReturn = 0;
  let distanceFromPickToDropOffReturn = 0;
  let totalMileage = 0;
  let totalMileageReturn = 0;
  const handleSubmit = async (event) => {
    event.preventDefault();
    beforeSubmit();
    if (Object.keys(values.connectedReservation).length === 0) {
      delete values.connectedReservation;
    }
    let spNo = values.loadNumber;
    if (!isSpecialNumber) {
      delete values.specialNumber;
    }
    values.rideHistory = stopArr;
    values.dateForReservation =
      stopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
    values.pickUpAddress = stopArr[0].address;
    values.pickUpDate = stopArr[0].date;
    values.pickUpTime = stopArr[0].time;
    values.dropOffAddress = stopArr[stopArr.length - 1].address;
    values.dropOffDate = stopArr[stopArr.length - 1].date;
    values.dropOffTime = stopArr[stopArr.length - 1].time;

    let abc = values.type;
    // if (values.type.id) {

    // }
    // else {

    // }
    if (abc.name === "Ambulatory") {
      delete values.numberOfWheelchairs;
      delete values.amSeats;
      delete values.needWheel;
      delete values.needAssigned;
      values.type = abc.id;
    } else if (abc.name === "Wheelchair") {
      delete values.seats;
      values.type = abc.id;
    } else if (abc.name === "Stretcher") {
      delete values.numberOfWheelchairs;
      delete values.amSeats;
      delete values.needWheel;
      delete values.needAssigned;
      delete values.seats;
      values.type = abc.id;
    }
    for (let key of Object.keys(values)) {
      if (values[key] === "") {
        delete values[key];
      }
    }
    const _id = values._id;
    delete values.id;
    delete values.driverBookingStatus;
    delete values.isAssigned;
    delete values.companyId;
    delete values.name;
    let cusId = "";
    if (values?.customerId?.id) {
      cusId = values.customerId.id;
      delete values.customerId;
    }
    // if (values?.driverId?.id) {
    //   delete values.driverId;
    // }
    if (values?.assetId?.id) {
      delete values.assetId;
    }
    const serviceName = values.fullName;
    delete values.fullName;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.__v;
    delete values.trip;
    // console.log(values, "values")
    if (values.loadNumber === null) {
      delete values.loadNumber;
    }
    if (values.returnTripIdentifier) {
      delete values.returnTripIdentifier;
    }
    if (values.loadNumber) {
      if (cusId === "") {
        cusId = values.customerId;
      }
      let loadNumberIdentifier = "cus:" + cusId + "-ln:" + values.loadNumber;
      // spNo = values.loadNumber
      if (isSpecialNumber) {
        // values.specialNumber = values.loadNumber;
        delete values.loadNumber;
        // console.log("values",values)
      }
      if (copyParam || returnTripParam) {
        delete values.status;
        delete values.delete;
        delete values._id;
        delete values.orderId;
        delete values.uniqueOrderId;
        delete values.driverCancellation;
        delete values.loadNumberIdentifier;
        delete values.completelyDelete;
        delete values.cancelReason;
        // console.log(values, "valuesvalues");

        // values.assignedDate =
        //   moment().format().toString().substring(0, 19) + ".000Z";
        dispatch(
          addReservation(
            {
              // ...values,
              ...(({ driverId, ...rest }) => rest)(values),
              customerId: otherValues.customerId,
              companyId: otherValues.companyId,
              serviceName: serviceName,
              actions: [
                {
                  userId: auth.user.id,
                  action: "Create",
                  details: `${auth.user.firstName} ${auth.user.lastName} has created the booking.`,
                },
              ],
              ...(isSpecialNumber && {
                specialNumber: spNo,
              }),
              // loadNumberIdentifier: loadNumberIdentifier,
            },
            reservationSuccessCB
          )
        );
      } else {
        if (
          driverIdForEdit?.id &&
          (values?.driverId?.id == driverIdForEdit?.id ||
            values?.driverId == driverIdForEdit?.id)
        ) {
          delete values.driverId;
        } else {
          if (values?.driverId === undefined && driverIdForEdit !== "") {
            delete values.driverId;
            values.removeDriver = true;
            values.assigneeId = auth.user.id;
          }
          values.assignedDate =
            moment().format().toString().substring(0, 19) + ".000Z";
        }
        delete values.delete;
        dispatch(
          updateReservation(
            {
              ...values,
              serviceName: serviceName,
              // loadNumberIdentifier: loadNumberIdentifier,
              dispatcherId: auth.user.id,
              _id: _id,
              ...(!isSpecialNumber && {
              loadNumberIdentifier: loadNumberIdentifier,
              }),
              ...(isSpecialNumber && {
                specialNumber: spNo,
              }),
            },
            navigateDispatching
          )
        );
        if (values?.facility && values?.connectedReservation?.reservationId){
          dispatch(
            updateReservation(
              {
                _id:values?.connectedReservation?.reservationId,
                facility:values?.facility,
              dispatcherId: auth.user.id,
              },
            )
          );
        }
      }
      // console.log(updateReservation, "updateReservation 579");
    } else {
      if (copyParam || returnTripParam) {
        delete values.status;
        delete values.delete;
        delete values._id;
        delete values.orderId;
        delete values.uniqueOrderId;
        delete values.driverCancellation;
        delete values.loadNumberIdentifier;
        delete values.completelyDelete;
        delete values.cancelReason;
        // values.assignedDate =
        //   moment().format().toString().substring(0, 19) + ".000Z";
        dispatch(
          addReservation(
            {
              // ...values,
              ...(({ driverId, ...rest }) => rest)(values),
              customerId: otherValues.customerId,
              companyId: otherValues.companyId,
              serviceName: serviceName,
              actions: [
                {
                  userId: auth.user.id,
                  action: "Create",
                  details: `${auth.user.firstName} ${auth.user.lastName} has created the booking.`,
                },
              ],
              // ...(isSpecialNumber&& {
              //   specialNumber: spNo,
              // }),
            },
            reservationSuccessCB
          )
        );
      } else {
        if (
          driverIdForEdit?.id &&
          (values?.driverId?.id == driverIdForEdit?.id ||
            values?.driverId == driverIdForEdit?.id)
        ) {
          delete values.driverId;
        } else {
          if (values?.driverId === undefined && driverIdForEdit !== "") {
            delete values.driverId;
            values.removeDriver = true;
            values.assigneeId = auth.user.id;
          }
          values.assignedDate =
            moment().format().toString().substring(0, 19) + ".000Z";
        }
        delete values.delete;
        dispatch(
          updateReservation(
            {
              ...values,
              serviceName: serviceName,
              _id: _id,
              dispatcherId: auth.user.id,
              // ...(isSpecialNumber&& {
              //   specialNumber: spNo,
              // }),
            },
            navigateDispatching
          )
        );
        if (values?.facility && values?.connectedReservation?.reservationId){
          dispatch(
            updateReservation(
              {
                _id:values?.connectedReservation?.reservationId,
                facility:values?.facility,
              dispatcherId: auth.user.id,
              },
            )
          );
        }
        // console.log(updateReservation, "updateReservation 647");
      }
    }
    setValues({
      ...values,
      customerId: cusId,
    });
    if (values?.facility){
      dispatch(editPassenger({_id: cusId,
        facility: values?.facility
      }));
      }
    afterSubmit();
  };
  const reservationSuccessCB = (createdReservationId) => {
    if (values?.driverId?.id) {
      const assignDriverData = {
        _id: createdReservationId,
        driverId: values?.driverId?.id ? values.driverId.id : values.driverId,
        assigneeId: auth.user.id,
        assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
        dispatcherId: auth.user.id,
      };
      if (assignDriverData.driverId != "" || !assignDriverData.driverId) {
        dispatch(
          updateReservation(assignDriverData, navigateDispatching, true)
        );
        socketInstance.emit("new-ride-req-send", {
          _id: assignDriverData._id,
          driverId: assignDriverData.driverId,
        });
      }
    }
    navigateDispatching();
  };
  // console.log(updateReservation, "updateReservation 676");
  const [displayClient, setDisplayClient] = useState(false);
  // const searchClient = async (data) => {
  //   dispatch(clientSearchFilter(data));
  //   setDisplayClient(true);
  // };
  const beforeSubmit = () => {
    stopArr.forEach((e) => {
      e.date = e.date.substring(0, 10) + "T" + e.time + ":00.000Z";
      // console.log(e, "eeee");
    });
    if (returnTrip === true) {
      returnStopArr.forEach((e) => {
        e.date = e.date.substring(0, 10) + "T" + e.time + ":00.000Z";
        // console.log(e, "eeee");
      });
    }
  };
  const afterSubmit = () => {
    stopArr.forEach((e) => {
      e.date = e.date.substring(0, 10);
    });
    if (returnTrip === true) {
      returnStopArr.forEach((e) => {
        e.date = e.date.substring(0, 10);
      });
    }
  };
  const [oldName, setOldName] = useState("");

  // useEffect(() => {
  //   dispatch(
  //     getAllClients({
  //       companyId: auth.user.companyId.id,
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   let timerOut = setTimeout(() => {
  //     if (oldName !== values.name) {
  //       if (values.name.length >= 2) {
  //         searchClient({
  //           // companyId: "63cb14f53f55723694dbd185",
  //           companyId: "auth.user.companyId.id",
  //           name: values.name,
  //         });
  //       }
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timerOut);
  // }, [values.name]);

  // const searchLocation = async (data) => {
  //   dispatch(getLocationsByUserId(data));
  // };
  const onClickCustomer = (data) => {
    const abc = JSON.parse(data);
    setDisplayClient(false);
    setOldName(abc.firstName + " " + abc.lastName);
    setValues({
      ...values,
      customerId: abc.id,
      name: abc.firstName + " " + abc.lastName,
    });
    // console.log(abc.isCompany === false, abc.isCompany, "abc.isCompany");
    if (abc.isCompany === false) {
      setDetails({ isCompany: abc.isCompany });
    } else {
      setDetails({
        isCompany: abc.isCompany,
        externalCompanyId: abc.externalCompanyId,
      });
    }
    // searchLocation({
    //   userId: abc.id
    // })
    setStopArr([
      {
        address: "",
        date: "",
        time: "",
      },
      {
        address: "",
        date: "",
        time: "",
      },
    ]);
  };
  // console.log(details, "dddettails");
  // yahan se new logic
  const handleVeryDirectFare = () => {
    // setHardCodedFares({
    //   ...hardCodedFares,
    //   // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
    //   [fieldName]: event.target.value,
    // });
    const dh =
      parseFloat(parseFloat(hardCodedFares.distanceFromBaseLocation)) >
      parseFloat(hardCodedFares?.deadHeadThreshold)
        ? parseFloat(
            parseFloat(
              parseFloat(hardCodedFares.distanceFromBaseLocation) *
                parseFloat(hardCodedFares?.baseFarePerMile)
            ).toFixed(2)
          )
        : 0;
    const temp = parseFloat(
      (
        parseFloat(
          parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2)
          ) * parseFloat(hardCodedFares.farePerMile)
        ) +
        // parseFloat(hardCodedFares.offHourFee ? hardCodedFares.offHourFee : 0) +
        parseFloat(hardCodedFares.flatBaseFare) +
        (values?.connectedReservation ? dh / 2 : dh)
      ).toFixed(2)
    );

    const tempD = !values?.connectedReservation
      ? parseFloat(
          ((temp - dh / 2) * (hardCodedFares.driverPercentage / 100)).toFixed(2)
        )
      : parseFloat((temp * (hardCodedFares.driverPercentage / 100)).toFixed(2));
    // console.log(
    //   parseFloat(hardCodedFares?.deadHeadThreshold),
    //   parseFloat(hardCodedFares.distanceFromBaseLocation),
    //   dh,
    //   "temporary"
    // );
    // console.log("fareDetails2");
    setFareDetails({
      ...fareDetails,
      distanceFromBaseLocation: parseFloat(
        parseFloat(hardCodedFares?.distanceFromBaseLocation)?.toFixed(2)
      ),
      distanceFromPickToDropOff: parseFloat(
        parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
      ),
      precalculatedMiles: parseFloat(
        parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
      ),
      apiDistanceFromPickToDropOff: hardCodedFares.apiDistanceFromPickToDropOff,
      usePrecalculatedMiles: hardCodedFares.usePrecalculatedMiles,
      totalMileage: parseFloat(
        (
          parseFloat(hardCodedFares.distanceFromBaseLocation) +
          parseFloat(hardCodedFares.distanceFromPickToDropOff)
        ).toFixed(2)
      ),
      baseFare: parseFloat(parseFloat(hardCodedFares.flatBaseFare).toFixed(2)),
      baseFareStructure: {
        values: {
          flatBaseFare: hardCodedFares.flatBaseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadheadFare: values?.connectedReservation ? dh / 2 : dh,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: hardCodedFares.baseFarePerMile,
          distanceFromBaseLocation: distanceFromBaseLocation,
        },
        valuesFormula: values?.connectedReservation
          ? "(baseFarePerMile * distanceFromBaseLocation)/2"
          : "baseFarePerMile * distanceFromBaseLocation",
      },
      rideFare: parseFloat(
        (
          parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
          parseFloat(hardCodedFares.farePerMile)
        ).toFixed(2)
      ),
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff: hardCodedFares.distanceFromPickToDropOff,
          farePerMile: hardCodedFares.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: parseFloat(hardCodedFares.offHourFee),
      amount: temp,
      amountStructure: {
        values: {
          rideFare: parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
              parseFloat(hardCodedFares.farePerMile)
          ).toFixed(2),
          // offHourFee: parseFloat(hardCodedFares.offHourFee),
          baseFare: parseFloat(hardCodedFares.flatBaseFare).toFixed(2),
          deadheadFare: values?.connectedReservation ? dh / 2 : dh,
        },
        // valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      },
      driverPercentage: hardCodedFares.driverPercentage,
      driverAmount: parseFloat(tempD.toFixed(2)),
      companyAmount: parseFloat((temp - tempD).toFixed(2)),
    });
    setAmount(temp);
    setMil(hardCodedFares.distanceFromPickToDropOff);
  };
  // yahan se new logic
  const [stopArrUseApi, setStopArrUseApi] = useState(false);
  // yahan takh new logic
  const [stopArr, setStopArr] = useState([
    {
      address: "",
      date: "",
      time: "",
    },
    {
      address: "",
      date: "",
      time: "",
    },
  ]);
  const [eta, setEta] = useState();
  // yahan se new logic
  const [useHardCodedFares, setUseHardCodedFares] = useState(true);
  const [hardCodedFares, setHardCodedFares] = useState({
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationFee: "",
    additionalCharges: 0,
    deduction: 0,
    precalculatedMiles: 0,
    usePrecalculatedMiles: false,
  });
  const handleHardCodedFares = (fieldName) => (event) => {
    setHardCodedFares({
      ...hardCodedFares,
      // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
      [fieldName]: event.target.value,
    });
  };
  const [useMiles, setUseMiles] = useState(false);
  const handleUsePrecalculatedMilesTrue = () => {
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: true,
    }));
    setUseMiles(true);
  };
  const handleUsePrecalculatedMilesFalse = () => {
    // Update usePrecalculatedMiles to true
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: false,
    }));
    setUseMiles(true);
  };

  useEffect(() => {
    if (useMiles) {
      recalculate();
      setUseMiles(false);
    }
  }, [useMiles]);

  const recalculate = () => {
    calculateFareFrontEnd();
  };
  // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
  const toogleHardCoded = () => {
    if (!useHardCodedFares === true) {
      setUseDirectFare(false);
      setUseFlatDriverRate(false);
    }
    setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
  };
  const [useDirectFare, setUseDirectFare] = useState(false);
  const [directFare, setDirectFare] = useState(0);
  const toogleDirectFare = () => {
    if (!useDirectFare === true) {
      setUseHardCodedFares(false);
      setUseFlatDriverRate(false);
    }
    setUseDirectFare((useDirectFare) => !useDirectFare);
  };
  const handleDirectFare = (event) => {
    setDirectFare(event.target.value);
  };
  const fareDirectlyCalculate = () => {
    // IDHAR KAAM HOGA DRIVER AMOUNT ORIGINAL USE HO RHI HAI FARE SE NAHI
    let driverAmount = parseFloat(
      (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(directFare - driverAmount);
    // console.log("fareDetails3");
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: parseFloat(directFare),
      amount: parseFloat(directFare),
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
    });
    setAmount(directFare);
  };
  // console.log(fareDetails, "fareDetails");
  const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
  const [flatDriverRate, setFlatDriverRate] = useState(0);
  const toogleFlatDriverRate = () => {
    if (!useFlatDriverRate === true) {
      setUseHardCodedFares(false);
      setUseDirectFare(false);
    }
    setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
  };
  const handleFlatDriverRate = (event) => {
    setFlatDriverRate(event.target.value);
  };
  const flatDriverRateRecalculate = () => {
    let driverAmount = parseFloat(flatDriverRate);
    let companyAmount = parseFloat(fareDetails.amount - driverAmount);
    // console.log("fareDetails4");
    setFareDetails((prevFareDetails) => ({
      ...prevFareDetails,
      driverAmount: parseFloat(driverAmount),
      companyAmount: companyAmount,
    }));
    // console.log(fareDetails, "fareDetails");
    setValues({
      ...values,
      ["fareAndDistance"]: {
        ...values.fareAndDistance,
        driverAmount: driverAmount,
        companyAmount: companyAmount,
      },
    });
    // setAmount(directFare);
  };
  // yahan takh new logic

  const calculateFareFrontEnd = async () => {
    // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
    // console.log("calculateFareFrontEnd")
    const abc = values.type;
    let newArr = [];
    stopArr.forEach((e) => {
      newArr.push(e?.address?.address);
    });
    let obj = {};
    // yahan se new logic
    let eta = { hour: [], mins: [] };
    // yahan takh new logic
    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: newArr[0],
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        // console.log("response", response);
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text
            .replace(/,/g, "")
            .split(" mi")[0]
        );
        obj.distanceFromBaseLocation = distanceFromBaseLocation;
      })
      .catch((e) => {
        notifyFailure(e.message);
        // console.log("Directions request failed due to ", e.message);
        // window.alert("Directions request failed due to ", e)
      });
    // yahan se new logic
    let oldDate = new Date(
      stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
    );
    // yahan takh new logic
    for (let i = 0; i < newArr.length - 1; i++) {
      await directionsService
        .route({
          origin: {
            query: newArr[i],
          },
          destination: {
            query: newArr[i + 1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          // console.log("response2", response);
          distanceFromPickToDropOff += parseFloat(
            response.routes[0].legs[0].distance.text
              .replace(/,/g, "")
              .split(" mi")[0]
          );
          // yahan se new logic
          if (response.routes[0].legs[0].duration.text.length > 7) {
            const timeString = response.routes[0].legs[0].duration.text;
            const hourRegex = /(\d+)\s*hour/;
            const minuteRegex = /(\d+)\s*min/;

            const hourMatch = hourRegex.exec(timeString);
            const minuteMatch = minuteRegex.exec(timeString);

            const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
            const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
            eta.hour.push(hours);
            eta.mins.push(minutes);

            // eta.hour.push(
            //   parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   )
            // );
            // let min =
            //   response.routes[0].legs[0].duration.text.split(" hour")[1];
            // eta.mins.push(parseInt(min.split(" mins")[0]));

            // let aaa =
            //   (parseInt(
            //     response.routes[0].legs[0].duration.text.split(" hour")[0]
            //   ) *
            //     60 +
            //     parseInt(min.split(" mins")[0])) *
            //   60 *
            //   1000;

            //old comment hai let oldDate wala
            // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();

            let aaa = (hours * 60 + minutes) * 60 * 1000;
            // console.log(aaa, oldDate, "eta aarha hai");
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   new Date(oldDate).toISOString(),
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          } else {
            eta.mins.push(
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              )
            );
            // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
            let aaa =
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              ) *
              60 *
              1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setStopArr(
              stopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
            // console.log(
            //   aaa,
            //   oldDate,
            //   oldDateTime.substring(0, 5),
            //   "eta aarha hai"
            // );
          }
          // console.log("etaeta,", eta);
          // yahan takh new logic
          // distanceFromPickToDropOff = distanceFromPickToDropOff + parseFloat(response.routes[0].legs[0].distance.text.replace(/,/g, '').split(" mi")[0])
          // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
        })
        .catch((e) => {
          notifyFailure(e.message);
          // console.log("Directions request failed due to ", e);
          // window.alert("Directions request failed due to ", e)
        });
    }
    // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
    distanceFromBaseLocation = parseFloat(distanceFromBaseLocation.toFixed(2));
    distanceFromPickToDropOff = parseFloat(
      distanceFromPickToDropOff.toFixed(2)
    );
    obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
    if (hardCodedFares.usePrecalculatedMiles === true) {
      obj.usePrecalculatedMiles = true;
      distanceFromPickToDropOff = parseFloat(
        parseFloat(hardCodedFares.precalculatedMiles).toFixed(2)
      );
      obj.precalculatedMiles = distanceFromPickToDropOff;
    } else {
      obj.usePrecalculatedMiles = false;
    }
    totalMileage = parseFloat(
      (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
    );
    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.totalMileage = totalMileage;
    let fares = fare.fare;
    let baseFare;
    let deadheadFare = 0;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;

    if (
      details.isCompany === false ||
      details.isCompany === "" ||
      !details.isCompany
    ) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        // console.log("=>",e.serviceId.id , abc,e.serviceId.id === abc.id)
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    // console.log("serviceFare=>", serviceFare, fares, companyFare)
    let _fare;
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        // console.log(_fare)
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    // console.log("_fare=>", _fare)
    // yahan se new logic
    // if (useHardCodedFares === true) {
    //   _fare = {
    //     flatBaseFare: parseFloat(hardCodedFares.flatBaseFare),
    //     farePerMile: parseFloat(hardCodedFares.farePerMile),
    //     deadHeadThreshold: parseFloat(hardCodedFares.deadHeadThreshold),
    //     baseFarePerMile: parseFloat(hardCodedFares.baseFarePerMile),
    //     totalMileageAllows: parseFloat(hardCodedFares.totalMileageAllows),
    //     longDistancePerMile: parseFloat(hardCodedFares.longDistancePerMile),
    //     offHourFee: parseFloat(hardCodedFares.offHourFee),
    //     noShowFee: parseFloat(hardCodedFares.noShowFee),
    //     cancelationFee: parseFloat(hardCodedFares.cancelationFee),
    //   };
    // }
    // yahan takh new logic
    obj.fare = _fare;
    if (distanceFromBaseLocation <= _fare?.deadHeadThreshold) {
      baseFare = _fare?.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare?.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
      obj.deadheadFare = 0;
      obj.deadheadFareStructure = {
        values: {
          baseFarePerMile: 0,
          distanceFromBaseLocation: distanceFromBaseLocation,
        },
        valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
      };
    } else {
      // yahan se new logic
      if (_fare?.deadHeadThreshold) {
        // baseFare =
        //   _fare?.flatBaseFare +
        //   _fare?.baseFarePerMile * distanceFromBaseLocation;
        // obj.baseFare = baseFare;
        // obj.baseFareStructure = {
        //   values: {
        //     flatBaseFare: _fare?.flatBaseFare,
        //     baseFarePerMile: _fare?.baseFarePerMile,
        //     distanceFromBaseLocation: distanceFromBaseLocation,
        //   },
        //   valuesFormula:
        //     "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
        // };
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: {
            flatBaseFare: _fare.flatBaseFare,
          },
          valuesFormula: "flatBaseFare",
        };
        deadheadFare = values?.connectedReservation
          ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
          : _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.deadheadFare = values?.connectedReservation
          ? (_fare.baseFarePerMile * distanceFromBaseLocation) / 2
          : _fare.baseFarePerMile * distanceFromBaseLocation;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: _fare.baseFarePerMile,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula: values?.connectedReservation
            ? "(baseFarePerMile * distanceFromBaseLocation)/2"
            : "baseFarePerMile * distanceFromBaseLocation",
        };
      } else {
        baseFare = _fare?.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: { flatBaseFare: _fare?.flatBaseFare },
          valuesFormula: "flatBaseFare",
        };
        obj.deadheadFare = 0;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: 0,
            distanceFromBaseLocation: distanceFromBaseLocation,
          },
          valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
        };
      }
      // yahan takh new logic
    }
    if (distanceFromPickToDropOff <= _fare?.totalMileageAllows) {
      // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
      rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare?.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      // yahan se new logic
      if (_fare?.totalMileageAllows) {
        rideFare = distanceFromPickToDropOff * _fare?.longDistancePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            longDistancePerMile: _fare?.longDistancePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
        };
      } else {
        rideFare = distanceFromPickToDropOff * _fare?.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare?.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
      }
      // yahan takh
    }

    const company = auth?.user?.companyId;
    // console.log(company)
    for (let key of Object.keys(company.availability)) {
      // console.log(key, key === (moment(stopArr[0].date).format("dddd")  !== 'Sunday' ? moment(stopArr[0].date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0].date).endOf('week').add(-6, 'days').format('dddd')), "checkss")
      // logic added if else, first one second one added
      // if (key === (moment(stopArr[0].date).format("dddd")  !== 'Sunday' ? moment(stopArr[0].date).endOf('week').add(1, 'days').format('dddd') : moment(stopArr[0].date).endOf('week').add(-6, 'days').format('dddd'))) {
      // }
      if (moment(stopArr[0].date).format("dddd") !== "Sunday") {
        if (key === moment(stopArr[0].date).format("dddd")) {
          if (company.availability[key] === false) {
            offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
      } else {
        if (
          key ===
          moment(stopArr[0].date).endOf("week").add(-6, "days").format("dddd")
        ) {
          if (company.availability[key] === false) {
            offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
      }
    }
    // console.log(company.offHours.from.substring(0,2))
    // console.log(company.offHours.from.substring(3,5))

    if (offHourFee === 0) {
      if (
        parseInt(company?.offHours?.from?.substring(0, 2)) <=
          parseInt(stopArr[0].time.substring(0, 2)) &&
        parseInt(stopArr[0].time.substring(0, 2)) <=
          parseInt(company?.offHours?.to?.substring(0, 2))
      ) {
        if (
          parseInt(company?.offHours?.from?.substring(3, 5)) <=
          parseInt(stopArr[0].time.substring(3, 5))
        ) {
          offHourFee += _fare?.offHourFee ? _fare?.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
      // else {
      //   console.log("normal")
      // }
    }

    if (offHourFee === 0) {
      amount = (rideFare + baseFare + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare + deadheadFare",
      };
      // yahan takh
    } else {
      // amount = (rideFare + baseFare + offHourFee + deadheadFare).toFixed(2);
      amount = (rideFare + baseFare + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          // offHourFee: offHourFee,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        // valuesFormula: "rideFare + baseFare + offHourFee + deadheadFare",
        valuesFormula: "rideFare + baseFare + deadheadFare",
      };
      // yahan takh
    }

    // yahan se new logic
    let m = 0;
    let hr = 0;
    let tempHr = 0;
    eta.mins.forEach((e) => {
      m += e;
    });
    tempHr = Math.floor(m / 60);
    m = m % 60;
    eta.hour.forEach((e) => {
      hr += e;
    });
    hr += tempHr;
    let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
    obj.estimatedTime = estimatedTime;
    let newDropOffEta = new Date(
      stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
    ).getTime();
    // console.log("etaeta,", hr, "hrs", m, "mins ", newDropOffEta);
    let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
    newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
    newDropOffEta = newDropOffEta.toString().split(" ")[4];
    let etaTime = newDropOffEta.substring(0, 5);
    obj.etaTime = etaTime;
    // console.log("etaeta,", hr, 'hrs', m, "mins ", newDropOffEta)
    let driverAmount = !values?.connectedReservation
      ? parseFloat(
          (
            (amount - deadheadFare / 2) *
            // (auth?.user?.companyId?.driverPercentage / 100)
            (_fare?.driverPercentage / 100)
          ).toFixed(2)
        )
      : parseFloat(
          // (amount * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
          (amount * (_fare?.driverPercentage / 100)).toFixed(2)
        );
    let companyAmount = parseFloat((amount - driverAmount).toFixed(2));
    // obj.driverPercentage = auth?.user?.companyId?.driverPercentage;
    obj.driverPercentage = _fare?.driverPercentage;
    obj.driverAmount = driverAmount;
    obj.companyAmount = companyAmount;
    // console.log("objobjobjobj", obj);
    // setEta(etaTime)
    setEta(estimatedTime);
    setHardCodedFares({
      ...hardCodedFares,
      flatBaseFare: _fare?.flatBaseFare,
      farePerMile: _fare?.farePerMile,
      deadHeadThreshold: _fare?.deadHeadThreshold,
      baseFarePerMile: _fare?.baseFarePerMile,
      totalMileageAllows: _fare?.totalMileageAllows,
      longDistancePerMile: _fare?.longDistancePerMile,
      offHourFee: _fare?.offHourFee,
      noShowFee: _fare?.noShowFee,
      cancelationFee: _fare?.cancelationFee,
      distanceFromBaseLocation: obj.distanceFromBaseLocation,
      apiDistanceFromPickToDropOff: obj.apiDistanceFromPickToDropOff,
      distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
      precalculatedMiles: obj.distanceFromPickToDropOff,
      usePrecalculatedMiles: obj.usePrecalculatedMiles,
      // driverPercentage: auth?.user?.companyId?.driverPercentage,
      driverPercentage: _fare?.driverPercentage,
      deadheadFare: deadheadFare == 0 ? 0 : deadheadFare,
      baseFarePerMile: deadheadFare == 0 ? 0 : _fare.baseFarePerMile,
      deadHeadThreshold:
        !_fare?.deadHeadThreshold || _fare?.deadHeadThreshold == NaN
          ? 0
          : _fare.deadHeadThreshold,
      // additionalCharges: 0,
      // deduction: 0,
    });
    // yahan takh new logic
    setAmount(obj.amount);
    setMil(obj.distanceFromPickToDropOff);
    setFareDetails(obj);
    // yahan se new logic
    setStopArrUseApi(false);
    // yahan takh new logic
    setValues({
      ...values,
      fareAndDistance: obj,
      totalMileage: obj.distanceFromPickToDropOff,
      totalAmount: obj.amount,
      // totalAmount: obj.driverAmount,
    });
  };
  // console.log(fareDetails, "main hoon fare details");

  useEffect(() => {
    // yahan se new logic
    if (stopArrUseApi === true) {
      if (stopArr.length >= 2) {
        let cond = true;
        // stopArr.forEach((e) => {
        //   for (let key of Object.keys(e)) {
        //     if (e[key] === "") {
        //       cond = false;
        //     }
        //   }
        // });
        stopArr.forEach((e) => {
          if (e.address === "") {
            cond = false;
          }
        });
        if (stopArr[0].time === "" || stopArr[0].date === "") {
          cond = false;
        }
        if (cond === true) {
          calculateFareFrontEnd();
        }
        // console.log((stopArr[0].time === '') && (stopArr[0].date === '' ),cond, "condcondcond")
      }
    }
    // yahan takh new logic
    // yahan se new logic
  }, [stopArr, stopArrUseApi, values]);
  // yahan takh new logic
  // console.log(stopArr, "stopArr");
  const handleStopChange = (index, field) => (event) => {
    // yahan se new logic
    setStopArrUseApi(true);
    // yahan takh new logic
    setStopArr(
      stopArr.map((stop, i) =>
        i === index ? { ...stop, [field]: event.target.value } : { ...stop }
      )
    );
  };
  // const increaseStop = () => {
  //   // yahan se new logic
  //   setStopArrUseApi(true);
  //   // yahan takh new logic
  //   setStopArr([
  //     ...stopArr,
  //     {
  //       address: "",
  //       date: "",
  //       time: "",
  //     },
  //   ]);
  // };
  // const decreaseStop = (index) => {
  //   // yahan se new logic
  //   setStopArrUseApi(true);
  //   // yahan takh new logic
  //   setStopArr(stopArr.filter((stop, i) => i !== index));
  // };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];

  const [values2, setValues2] = useState({
    name: "",
    customerId: "",
    type: "",
    bookingDate: "",
    seats: "1",
    willCall: false,
    needAttended: false,
    numberOfWheelchairs: "1",
    amSeats: "0",
    needWheel: false,
    needAssigned: false,
    pickUpAddress: "",
    pickUpDate: "",
    pickUpTime: "",
    dropOffAddress: "",
    dropOffDate: "",
    dropOffTime: "",
    dateForReservation: "",
    driverNotes: "",
    dispatchNotes: "",
    distanceFromBaseLocation: "",
    distanceFromPickToDropOff: "",
    totalMileage: "",
    // loadNumber: '',
    // clientIdExternal: '',
    fareAndDistance: "",
    rideHistory: [],
    externalCompanyId: "",
    totalAmount: "",
  });
  const [returnStopArr, setReturnStopArr] = useState([]);
  // console.log("values2", values2);
  const returnReservationData = {
    companyId: auth.user.companyId.id,
    customerId: values2.customerId,
    type: values.type,
    bookingDate: moment().format("YYYY-MM-DD"),
    seats: values2.seats,
    willCall: values2.willCall,
    needAttended: values2.needAttended,
    numberOfWheelchairs: values2.numberOfWheelchairs,
    amSeats: values2.amSeats,
    needWheel: values2.needWheel,
    needAssigned: values2.needAssigned,
    pickUpAddress: values2.pickUpAddress,
    pickUpDate: values2.pickUpDate,
    pickUpTime: values2.pickUpTime,
    dropOffAddress: values2.dropOffAddress,
    dropOffDate: values2.dropOffDate,
    dropOffTime: values2.dropOffTime,
    dateForReservation: values2.dateForReservation,
    driverNotes: values2.driverNotes,
    dispatchNotes: values2.dispatchNotes,
    distanceFromBaseLocation: values2.distanceFromBaseLocation,
    distanceFromPickToDropOff: values2.distanceFromPickToDropOff,
    totalMileage: values2.totalMileage,
    rideHistory: values2.rideHistory,
    totalAmount: values2.totalAmount,
    // totalAmount: values2.fareAndDistance.driverAmount,
    externalCompanyId: values2.externalCompanyId
      ? details.externalCompanyId
      : "",
    fareAndDistance: values2.fareAndDistance,
    // loadNumber: values2.loadNumber,
    // clientIdExternal: values2.clientIdExternal,
  };

  // console.log(stopArr, "values==>")
  // const previousLocation = async (id) => {
  //   const { data } = await axios.post(
  //     `${SERVERADDRESS}/v1/reservation/getLastReservationOfCustomerById`,
  //     {customerId: id}
  //   );
  //   console.log(data, "data re data")
  //   setStopArr([
  //     {
  //       address: data?.pickUpAddress ? data?.pickUpAddress : '',
  //       date: "",
  //       time: "",
  //     },
  //     {
  //       address: data?.dropOffAddress ? data?.dropOffAddress : '',
  //       date: "",
  //       time: "",
  //     },
  //   ]);
  // }

  const [showDropDownVehicleService, setshowDropDownVehicleService] =
    useState(false);

  const openDropDownVehicleService = () => {
    setshowDropDownVehicleService(
      (showDropDownVehicleService) => !showDropDownVehicleService
    );
  };

  const [serviceNames, setServiceNames] = useState([]);
  // const isInitialRender = useRef(true);
  // useEffect(() => {
  //   // This code will run after the first render
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //   } else {
  //     // This code will run from the second render onwards
  //     if (serviceNames) {
  //       // Place your effect code here
  //       recalculate();
  //     }
  //   }
  // }, [serviceNames]);

  const handleChangeType = (fieldName) => (event) => {
    const sType = JSON.parse(event.target.value);
    const sNames = service?.service.find((x) => x.id === sType.id);
    // console.log(sNames, sType.id, "sNamessNames")
    setValues({
      ...values,
      [fieldName]: event.target.value,
      ["fullName"]: sNames?.serviceName[0]?.fullName,
    });
    setServiceNames(sNames.serviceName);
  };

  const reverseArr = () => {
    setStopArr(stopArr.slice().reverse());
    setStopArrUseApi(true);
  };
  // const findSuggestedDriver = async () => {
  //   if (values.type && stopArr[0].address) {
  //     const abc = JSON.parse(values.type);
  //   }
  // };
  const [arr, setArr] = useState([]);
  const suggestedNearbyDrivers = async () => {
    // console.log("kkkkkkkkkkkkkkkkkkkkkkk");
    // console.log(values, stopArr, "values kkkkkkkkkkkkkkkkkkkkkkk");
    if (
      values.type &&
      stopArr[0].address &&
      stopArr[0].time &&
      stopArr[stopArr.length - 1].time
    ) {
      let abc;
      if (values.type.id) {
        abc = values.type;
      } else {
        abc = JSON.parse(values.type);
      }
      const { data } = await axios.post(
        `${SERVERADDRESS}/v2/reservation/suggestedNearbyDrivers`,
        {
          companyId: auth?.user?.companyId?.id,
          // driverId: auth?.user?.id,
          serviceType: abc.id,
          lng: stopArr[0].address.longitude,
          lat: stopArr[0].address.latitude,
          pickUpTime: stopArr[0].time,
          dropOffTime: stopArr[stopArr.length - 1].time,
        }
      );
      setArr(data);
    }
  };
  // console.log(values, "values ======>");
  useEffect(() => {
    suggestedNearbyDrivers();
  }, [stopArr, values]);

  const [selectedDriver, setSelectedDriver] = useState(null);

  const handleCheckboxChange3 = (driverId) => {
    setSelectedDriver(driverId);
    setValues({
      ...values,
      driverId: driverId,
    });
  };

  return auth?.user?.permissionId?.booking?.create === true ? (
    <>
      {/* <div className="content-wrapper"> */}
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <form onSubmit={handleSubmit}>
            <div className="row p-20">
              <div className="col-lg-12">
                <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey pb-3 px-2">
                  <h4 className="f-18 f-w-500 mb-0">Edit Reservation</h4>

                  <NavLink
                    to="/scheduled-bookings"
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => openDropDown()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </NavLink>

                  {/* <div
                      className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                      role="group"
                    >
                      <Link
                        to="/today-bookings"
                        type="button"
                        className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                        id="add-lead"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>{" "}
                        Today's Bookings
                      </Link>
                      <Link
                        to="/past-bookings"
                        type="button"
                        className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                        id="add-lead"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>{" "}
                        Past Bookings
                      </Link>
                    </div> */}
                </div>
                <div className=" bg-white border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                  <h4 className="f-18 f-w-500 mb-0">Basic info</h4>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
                    <div className="row">
                      <div className="col-sm-2 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="default_task_status"
                              >
                                Load#
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                checked={isSpecialNumber}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label pl-1 f-13 text-dark mx-1">
                                Special#
                              </label>
                            </div>
                          </div>
                          <div className="dropdown bootstrap-select form-control select-picker">
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Load Number"
                              // onKeyDown={(e) => {
                              //   if (e.ctrlKey && e.code === "KeyV") {
                              //     return;
                              //   }
                              //   if (alphabets.includes(e.key)) {
                              //     e.preventDefault();
                              //   }
                              // }}
                              value={
                                values.loadNumber
                                // ? values.loadNumber
                                // : values.specialNumber
                              }
                              onChange={handleChange("loadNumber")}
                              // onChange={handleChange(
                              //   isSpecialNumber ? "specialNumber" : "loadNumber"
                              // )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="clnumber"
                                >
                                  Customer Name
                                </label>
                              </div>
                              <div className="p-1">
                                <sup className="text-danger f-12">*</sup>
                              </div>
                            </div>
                            <div>
                              <button
                                className="bg-button"
                                type="button"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                                onClick={openDropDown}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          {/* <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={""}
                              // value={users?.allClients
                              //   ?.map((x) => ({
                              //     label: `${x.firstName} ${x.lastName}`,
                              //     value: x.id,
                              //   }))
                              //   .find(
                              //     (customer) => customer.value === values.name
                              //   )}
                              isDisabled={false}
                              // isLoading={searchingClients}
                              isClearable={false}
                              isRtl={false}
                              isSearchable={true}
                              required={true}
                              name="pickUp"
                              // onInputChange={(e) =>
                              //   setValues((prev) => ({ ...prev, name: e }))
                              // }
                              onChange={(e) =>
                                {
                                  setValues({
                                    ...values,
                                    customerId: e.value,
                                    name: e.label,
                                    isCompany: e?.isCompany && e?.isCompany,
                                    externalCompanyId:
                                      e?.externalCompanyId
                                        ? e.externalCompanyId
                                        : "",
                                  })
                                  if (e.isCompany === false) {
                                    setDetails({ isCompany: e.isCompany })
                                  } else {
                                    setDetails({
                                      isCompany: e.isCompany,
                                      externalCompanyId: e.externalCompanyId,
                                    })
                                  }
                                  previousLocation(e.value)
                                }
                              }
                              options={users?.allClients?.map((x) => ({
                                label: `${x.firstName} ${x.lastName} `,
                                value: x.id,
                                isCompany: x?.isCompany && x?.isCompany,
                                externalCompanyId:
                                  x?.externalCompanyId
                                    ? x.externalCompanyId
                                    : "",
                              }))}
                            /> */}
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={""}
                            // value={users?.allClients
                            //   ?.map((x) => ({
                            //     label: `${x.firstName} ${x.lastName}`,
                            //     value: x.id,
                            //   }))
                            //   .find(
                            //     (customer) => customer.value === values?.customerId?.id
                            //   )}
                            value={students?.list
                              ?.map((x) => ({
                                label: `${x.firstName} ${x.lastName}`,
                                value: x.id,
                              }))
                              .find(
                                (customer) =>
                                  customer.value ===
                                  (values?.customerId?.id
                                    ? values?.customerId?.id
                                    : values?.customerId)
                              )}
                            isDisabled={false}
                            // isLoading={searchingClients}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            required={true}
                            name="pickUp"
                            // onInputChange={(e) =>
                            //   setValues((prev) => ({ ...prev, name: e }))
                            // }
                            // value={data?.customerId?.id}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                customerId: e.value,
                                name: e.label,
                                isCompany: e?.isCompany && e?.isCompany,
                                externalCompanyId: e?.externalCompanyId
                                  ? e.externalCompanyId
                                  : "",
                              });
                              if (e.isCompany === false) {
                                setDetails({ isCompany: e.isCompany });
                              }
                              // yahan se new log
                              else if (e.isCompany === true) {
                                setDetails({
                                  isCompany: e.isCompany,
                                  externalCompanyId: e.externalCompanyId,
                                });
                              } else {
                                setDetails({ isCompany: "" });
                              }
                              // yahan takh new logic
                              setStopArrUseApi(true);
                              // recalculate()
                              // previousLocation(e.value)
                            }}
                            // options={users?.allClients  ?.map((x) => ({
                            //   label: `${x.firstName} ${x.lastName} `,
                            //   value: x.id,
                            //   isCompany: x?.isCompany && x?.isCompany,
                            //   externalCompanyId:
                            //     x?.externalCompanyId
                            //       ? x.externalCompanyId
                            //       : "",
                            // }))}
                            options={students?.list?.map((x) => ({
                              label: `${x.firstName} ${x.lastName} `,
                              value: x.id,
                              isCompany: x?.isCompany && x?.isCompany,
                              externalCompanyId: x?.externalCompanyId
                                ? x.externalCompanyId
                                : "",
                            }))}
                          />
                          {/* <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Search Customer Name..."
                              value={values.name}
                              onChange={handleChange("name")}
                              required
                            /> */}

                          {/* <br />
                            {displayClient === true && (
                              <div style={{ marginTop: "-22px" }}>
                                <ul className="ul-css">
                                  {kpi.searchedCustomer &&
                                  kpi.searchedCustomer.length > 0 ? (
                                    kpi.searchedCustomer.map((current, i) => {
                                      return (
                                        <li
                                          className="li-css"
                                          style={{ cursor: "pointer" }}
                                          key={i}
                                          onClick={() =>
                                            onClickCustomer(
                                              JSON.stringify({
                                                id: current.id,
                                                firstName: current.firstName,
                                                lastName: current.lastName,
                                                isCompany: current.isCompany,
                                                externalCompanyId:
                                                  current.externalCompanyId
                                                    ? current.externalCompanyId
                                                    : "",
                                              })
                                            )
                                          }
                                        >
                                          {current.firstName +
                                            " " +
                                            current.lastName}
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <li className="li-css" key={"emp"}>
                                      No data
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )} */}
                        </div>
                      </div>
                      <div className="col-sm-2 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                htmlFor="loadNumber"
                              >
                                Facility
                              </label>
                            </div>
                          </div>
                          <div className="dropdown bootstrap-select form-control select-picker">
                            {/* <input
                              type="text"
                              id="Facility"
                              name="Facility"
                              className="form-control height-35 f-14"
                              placeholder="Facility"
                              value={values.facility}
                              onChange={handleChange("facility")}
                            /> */}
                             <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={""}
                            value={facility
                              ?.map((x) => ({
                                label: `${x.name}`,
                                value: x.id,
                              }))
                              .find(
                                (e) =>
                                  e.value === values?.facility
                              )}
                            isDisabled={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            // required={true}
                            name="facility"
                            id="facility"
                            // onChange={handleChange("facility")}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                facility: e.value,
                              });
                            }}
                            options={facility?.map((x) => ({
                              label: `${x.name}`,
                              value: x.id,
                            }))}
                          />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Service Type
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div className="select-picker">
                            <select
                              className="form-control select-picker"
                              style={{ height: "39px", fontSize: "14px" }}
                              value={values?.type?.id}
                              onChange={handleChange("type")}
                              required
                            >
                              <option value="" key={"st"}>
                                {" "}
                                Select Type
                              </option>
                              {service.service &&
                                service.service.length > 0 &&
                                service.service.map((current, i) => {
                                  return (
                                    <option
                                      // value={JSON.stringify({
                                      //   id: current.id,
                                      //   name: current.name,
                                      // })}
                                      value={current.id}
                                      key={i}
                                    >
                                      {current.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                      {values &&
                        values.type &&
                        values.type !== "" &&
                        // JSON.parse(values.type).name === "Ambulatory" && (
                        values.type.name === "Ambulatory" && (
                          <div className="col-sm-3 pl-4">
                            <div className="form-group my-3">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      Service Name
                                    </label>
                                  </div>
                                  <div className="p-1">
                                    {/* <sup className="text-danger f-12">*</sup> */}
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className="bg-button"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    onClick={openDropDownVehicleService}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div className="select-picker">
                                <select
                                  className="form-control select-picker"
                                  style={{ height: "39px" }}
                                  value={values.fullName}
                                  onChange={handleChange("fullName")}
                                  // required
                                >
                                  <option value={""} key={""}>
                                    {" "}
                                    Select Service Name
                                  </option>
                                  {serviceNames.length > 0 &&
                                    serviceNames.map((current, i) => {
                                      return (
                                        <option
                                          value={current.fullName}
                                          key={i}
                                        >
                                          {" "}
                                          {current.fullName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                          // <div className="col-sm-2 details ambulatory show">
                          //   <div className="form-group my-3">
                          //     <div className="d-flex">
                          //       <div>
                          //         <label
                          //           className="f-14 text-dark mb-12"
                          //           data-label=""
                          //           htmlFor="clnumber"
                          //         >
                          //           Seats
                          //         </label>
                          //       </div>
                          //       <div className="p-1">
                          //         <sup className="text-danger f-12">*</sup>
                          //       </div>
                          //     </div>
                          //     <div className="select-picker">
                          //       <select
                          //         className="form-control select-picker"
                          //         style={{ height: "39px" }}
                          //         value={values.seats}
                          //         onChange={handleChange("seats")}
                          //       >
                          //         <option value="1"> 1</option>
                          //         <option value="2"> 2</option>
                          //         <option value="3"> 3</option>
                          //         <option value="4"> 4</option>
                          //       </select>
                          //     </div>
                          //   </div>
                          // </div>
                        )}
                      {values &&
                        values.type &&
                        values.type !== "" &&
                        // JSON.parse(values.type).name === "Wheelchair" && (
                        values.type.name === "Wheelchair" && (
                          <div className="col-sm-3 pl-4">
                            <div className="form-group my-3">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      Service Name
                                    </label>
                                  </div>
                                  <div className="p-1">
                                    {/* <sup className="text-danger f-12">*</sup> */}
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className="bg-button"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    onClick={openDropDownVehicleService}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div className="select-picker">
                                <select
                                  className="form-control select-picker"
                                  style={{ height: "39px" }}
                                  value={values.fullName}
                                  onChange={handleChange("fullName")}
                                  // required
                                >
                                  <option value={""} key={""}>
                                    {" "}
                                    Select Service Name
                                  </option>
                                  {serviceNames.length > 0 &&
                                    serviceNames.map((current, i) => {
                                      return (
                                        <option
                                          value={current.fullName}
                                          key={i}
                                        >
                                          {" "}
                                          {current.fullName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                          // <div className="col-sm-2 pl-4 details wheelchair show">
                          //   <div className="form-group my-3">
                          //     <div className="d-flex">
                          //       <div>
                          //         <label
                          //           className="f-14 text-dark mb-12"
                          //           data-label=""
                          //           htmlFor="clnumber"
                          //         >
                          //           Wheelchairs
                          //         </label>
                          //       </div>
                          //       <div className="p-1">
                          //         <sup className="text-danger f-12">*</sup>
                          //       </div>
                          //     </div>
                          //     <div className="select-picker">
                          //       <select
                          //         name="default_task_status"
                          //         id="default_task_status"
                          //         className="form-control select-picker"
                          //         style={{ height: "39px" }}
                          //         data-size="8"
                          //         tabIndex="null"
                          //         value={values.numberOfWheelchairs}
                          //         onChange={handleChange(
                          //           "numberOfWheelchairs"
                          //         )}
                          //       >
                          //         <option value="1"> 1</option>
                          //         <option value="2"> 2</option>
                          //         <option value="3"> 3</option>
                          //         <option value="4"> 4</option>
                          //       </select>
                          //     </div>
                          //   </div>
                          // </div>
                        )}
                      {/* {values &&
                          values.type &&
                          values.type !== "" &&
                          // JSON.parse(values.type).name === "Wheelchair" && (
                          !values.type.name && (
                            <div className="col-sm-3 pl-4">
                              <div className="form-group my-3">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex">
                                    <div>
                                      <label
                                        className="f-14 text-dark mb-12"
                                        data-label=""
                                        htmlFor="clnumber"
                                      >
                                        Service Name
                                      </label>
                                    </div>
                                    <div className="p-1">
                                      <sup className="text-danger f-12">*</sup>
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      className="bg-button"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalCenter"
                                      onClick={openDropDownVehicleService}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                                <div className="select-picker">
                                  <select
                                    className="form-control select-picker"
                                    style={{ height: "39px" }}
                                    value={values.fullName} onChange={handleChange('fullName')} required
                                  >
                                    {
                                      serviceNames.length > 0 &&
                                      serviceNames.map((current, i) => {
                                        return(
                                          <option value={current.fullName} key={i}> {current.fullName}</option> 
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          )} */}
                    </div>
                  </div>

                  {values &&
                    values.type &&
                    values.type !== "" &&
                    // JSON.parse(values.type).name === "Ambulatory" && (
                    values.type.name === "Ambulatory" && (
                      <div className="col-xl-4 col-lg-12 col-md-12 e-d-tasks-projects-events details ambulatory show">
                        <div className="row">
                          {values.returnTripIdentifier && (
                            <div className="col-sm-6">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      Will Call
                                    </label>
                                  </div>
                                  <div className="p-1">
                                    <sup className="text-danger f-12">*</sup>
                                  </div>
                                </div>
                                <div className="btn-choice">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      checked={values.willCall}
                                      onChange={() => {
                                        setWillCall((willCall) => !willCall);
                                        setValues({
                                          ...values,
                                          willCall: !values.willCall,
                                        });
                                        if (returnTrip === false) {
                                          onClickReturnTrip();
                                        }
                                      }}
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-sm-6">
                            <div className="form-group my-3">
                              <div className="d-flex">
                                <div>
                                  <label
                                    className="f-14 text-dark mb-12"
                                    data-label=""
                                    htmlFor="clnumber"
                                  >
                                    Need Attended
                                  </label>
                                </div>
                                <div className="p-1">
                                  <sup className="text-danger f-12">*</sup>
                                </div>
                              </div>
                              <div className="btn-choice">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    onChange={() =>
                                      setValues({
                                        ...values,
                                        needAttended: !values.needAttended,
                                      })
                                    }
                                    // required
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="YES"
                                    data-off="NO"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* {values &&
                      values.type &&
                      values.type !== "" &&
                      // JSON.parse(values.type).name === "Wheelchair" && (
                      values.type.name === "Wheelchair" && (
                        <div className="col-xl-4 col-lg-12 col-md-12 e-d-tasks-projects-events details wheelchair show">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      AM Seats
                                    </label>
                                  </div>
                                  <div className="p-1">
                                    <sup className="text-danger f-12">*</sup>
                                  </div>
                                </div>
                                <div className="select-picker">
                                  <select
                                    name="default_task_status"
                                    style={{ height: "39px" }}
                                    id="default_task_status"
                                    className="form-control select-picker"
                                    data-size="8"
                                    tabIndex="null"
                                    value={values.amSeats}
                                    onChange={handleChange("amSeats")}
                                  >
                                    <option value="0"> 0</option>
                                    <option value="1"> 1</option>
                                    <option value="2"> 2</option>
                                    <option value="3"> 3</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                </div>
              </div>
              {values &&
                values.type &&
                values.type !== "" &&
                // JSON.parse(values.type).name === "Wheelchair" && (
                values.type.name === "Wheelchair" && (
                  <div className="col-md-12 details wheelchair show">
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-12 e-d-info-notices">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group my-3">
                              <div className="d-flex">
                                <div>
                                  <label
                                    className="f-14 text-dark mb-12"
                                    data-label=""
                                    htmlFor="clnumber"
                                  >
                                    {" "}
                                    Need Wheel
                                  </label>
                                </div>
                                <div className="p-1">
                                  <sup className="text-danger f-12">*</sup>
                                </div>
                              </div>
                              <div className="btn-choice">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    onChange={() =>
                                      setValues({
                                        ...values,
                                        needWheel: !values.needWheel,
                                      })
                                    }
                                    // required
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="YES"
                                    data-off="NO"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          {values.returnTripIdentifier && (
                            <div className="col-sm-6">
                              <div className="form-group my-3">
                                <div className="d-flex">
                                  <div>
                                    <label
                                      className="f-14 text-dark mb-12"
                                      data-label=""
                                      htmlFor="clnumber"
                                    >
                                      {" "}
                                      Will Call
                                    </label>
                                  </div>
                                  <div className="p-1">
                                    <sup className="text-danger f-12">*</sup>
                                  </div>
                                </div>
                                <div className="btn-choice">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className="switch-input"
                                      checked={values.willCall}
                                      onChange={() => {
                                        setWillCall((willCall) => !willCall);
                                        setValues({
                                          ...values,
                                          willCall: !values.willCall,
                                        });
                                        if (returnTrip === false) {
                                          onClickReturnTrip();
                                        }
                                      }}
                                      // required
                                    />
                                    <span
                                      className="switch-label"
                                      data-on="YES"
                                      data-off="NO"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-12 e-d-tasks-projects-events">
                        <div className="row">
                          <div className="col-sm-6 pl-4">
                            <div className="form-group my-3">
                              <div className="d-flex">
                                <div>
                                  <label
                                    className="f-14 text-dark mb-12"
                                    data-label=""
                                    htmlFor="clnumber"
                                  >
                                    Need Assigned
                                  </label>
                                </div>
                                <div className="p-1">
                                  <sup className="text-danger f-12">*</sup>
                                </div>
                              </div>
                              <div>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    onChange={() =>
                                      setValues({
                                        ...values,
                                        needAssigned: !values.needAssigned,
                                      })
                                    }
                                    // required
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="YES"
                                    data-off="NO"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 pl-4">
                            <div className="form-group my-3">
                              <div className="d-flex">
                                <div>
                                  <label
                                    className="f-14 text-dark mb-12"
                                    data-label=""
                                    htmlFor="clnumber"
                                  >
                                    Need Attended
                                  </label>
                                </div>
                                <div className="p-1">
                                  <sup className="text-danger f-12">*</sup>
                                </div>
                              </div>
                              <div>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    onChange={() =>
                                      setValues({
                                        ...values,
                                        needAttended: !values.needAttended,
                                      })
                                    }
                                    // required
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="YES"
                                    data-off="NO"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {stopArr.length > 0 &&
                stopArr.map((current, i) => {
                  return (
                    <>
                      {/* <div className="col-sm-3 pl-4"> */}
                      <div className="col-sm-4 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div className="d-flex">
                                <div>
                                  <label
                                    className="f-14 text-dark mb-12"
                                    data-label=""
                                    htmlFor="clnumber"
                                  >
                                    {i === 0
                                      ? "Pickup Address"
                                      : "Dropoff Address"}
                                  </label>
                                </div>
                                <div className="p-1">
                                  <sup className="text-danger f-12">*</sup>
                                </div>
                                {i === 0 ? (
                                  <i
                                    className="fa fa-retweet"
                                    onClick={reverseArr}
                                  >
                                    {" "}
                                  </i>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div>
                              <button
                                className="bg-button"
                                type="button"
                                data-toggle="modal"
                                data-target="#exampleModalCenter2"
                                onClick={openDropLoc}
                              ></button>
                            </div>
                          </div>
                          <SearchedByMap
                            stopArr={stopArr}
                            setStopArr={setStopArr}
                            field="address"
                            index={i}
                            setStopArrUseApi={setStopArrUseApi}
                            searchAddress={stopArr[i].address.address}
                          />
                          {/* {
                                values.customerId ?
                                <select className="form-control select-picker" 
                                style={{ height: "39px", fontSize: "14px" }}
                                value={stopArr[i].address} 
                                onChange={handleStopChange(i,'address')}
                                required
                                >
                                    <option value="" key={"st"}> Select Location</option>
                                    { 
                                    location.searchedLocation &&
                                    location.searchedLocation.length > 0 &&
                                    location.searchedLocation.map((current, i) => {
                                        return (
                                        <option value={current.id} key={i}>{current.name}</option>
                                        )
                                    })
                                    }
                                </select>
                                :
                                <input type="text" className="form-control height-35 f-14" placeholder="Select Customer First" id="driver" min="0" autoComplete="off" disabled/>
                            } */}
                        </div>
                      </div>
                      <div className="col-sm-3 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                {i === 0 ? "Pickup Date" : "Dropoff Date"}
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          {i === 0 ? (
                            <input
                              type="date"
                              className="form-control height-35 f-14"
                              placeholder=""
                              id="driver"
                              min="0"
                              // autoComplete="off"
                              value={stopArr[i]?.date
                                ?.toString()
                                .substring(0, 10)}
                              onChange={handleStopChange(i, "date")}
                              required
                            />
                          ) : (
                            <input
                              type="date"
                              className="form-control height-35 f-14"
                              placeholder=""
                              id="driver"
                              min="0"
                              // autoComplete="off"
                              value={stopArr[i]?.date
                                ?.toString()
                                .substring(0, 10)}
                              onChange={handleStopChange(i, "date")}
                              required
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="driver"
                              >
                                {i === 0 ? "Pickup Time" : "Dropoff Time"}
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          {i === 0 ? (
                            <input
                              type="time"
                              className="form-control height-35 f-14"
                              placeholder=""
                              id="driver"
                              min="0"
                              // autoComplete="off"
                              value={stopArr[i].time}
                              onChange={handleStopChange(i, "time")}
                              required
                            />
                          ) : (
                            <input
                              type="time"
                              className="form-control height-35 f-14"
                              placeholder=""
                              id="driver"
                              min="0"
                              // autoComplete="off"
                              value={stopArr[i].time}
                              onChange={handleStopChange(i, "time")}
                              required
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      {i === 0 ? (
                        amount !== 0 && (
                          <div className="col-md-2 pl-4">
                            <button
                              type="button"
                              className="detail-button btn-lightest-grey rounded f-12 p-2 mr-3 "
                              // onClick={increaseStop}
                              // style={{
                              //   marginTop: "52px",
                              //   marginLeft: "-20px",
                              // }}
                              onClick={openDetailDropDown}
                            >
                              Details
                            </button>
                            <div
                              className="payment-price"
                              id="divMsg"
                              style={{ display: "block" }}
                              // onClick={}
                            >
                              <div className="circle-pricing">
                                <p>$ {amount}</p>
                              </div>
                              <div className="mile-text">
                                <p>Order {mil}mi</p>
                              </div>
                              <div className="mile-text2">
                                <p>ETA {eta}</p>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        <></>
                      )}

                      {/* <div className="form-group my-5">
                          {i !== 0 ? (
                            <button
                              type="button"
                              className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                              onClick={increaseStop}
                            >
                              Add A Stops
                            </button>
                          ) : (
                            <></>
                          )}
                          {i >= 2 && (
                            <button
                              type="button"
                              className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
                              id="save-form"
                              onClick={() => decreaseStop(i)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash3-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                              </svg>
                            </button>
                          )}
                        </div> */}
                    </>
                  );
                })}

              {/* {
                        stopArr.length >= 2 &&
                        stopArr[1].address !== '' &&
                        amount !== 0 &&
                        <div className="col-lg-12">
                            <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey">
                            </div>
                            <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey" style={{borderBottom: "#fff"}}>
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-4">
                                </div>
                                <div className="col-lg-3" style={{marginTop:"-4.375rem"}}>
                                    <Link to="">
                                    <div className="badges">
                                        <br/>
                                        <br/>
                                        <p>
                                            <span className="firstLine">Total Amount</span><br/>
                                            <span className="secondLine">${amount}</span><br/>
                                            <span className="thirdLine">Distance</span><br/>
                                            <span className="fourthLine">{mil}mi</span>
                                        </p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    } */}

              {returnTrip === true && (
                <>
                  <div className="col-lg-12">
                    <div className="bg-white text-capitalize d-flex justify-content-between  border-bottom-grey pb-3 px-2">
                      <h4 className="f-18 f-w-500 mb-0">Return Trip Details</h4>
                      <div
                        className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                        role="group"
                      >
                        <button
                          type="button"
                          className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                          id="add-lead"
                          onClick={onClickCloseReturnTrip}
                        >
                          <span aria-hidden="true">&times;</span> Close
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                  <AdminBookingReturnTrip
                    returnTrip={returnTrip}
                    onClickReturnTrip={onClickReturnTrip}
                    newArrForRT={newArrForRT}
                    values={values}
                    values2={values2}
                    setValues2={setValues2}
                    returnStopArr={returnStopArr}
                    setReturnStopArr={setReturnStopArr}
                    details={details}
                    willCall={willCall}
                  />
                </>
              )}

              <div className="col-lg-12">
                <div className="bg-white text-capitalize d-flex justify-content-between  border-bottom-grey"></div>
                <div className="bg-white border-0 text-capitalize d-flex justify-content-between pt-3 px-2">
                  <h4 className="f-18 f-w-500 mb-0">Notes</h4>
                </div>
              </div>
              <div className="col-sm-6 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="default_task_status"
                      >
                        Driver Notes
                      </label>
                    </div>
                  </div>
                  <div className="dropdown bootstrap-select form-control select-picker">
                    <textarea
                      rows="2"
                      cols="50"
                      className="form-control f-14"
                      value={values.driverNotes}
                      onChange={handleChange("driverNotes")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="default_task_status"
                      >
                        Dispatch Notes
                      </label>
                    </div>
                  </div>
                  <div className="dropdown bootstrap-select form-control select-picker">
                    <textarea
                      rows="2"
                      cols="50"
                      className="form-control f-14"
                      value={values.dispatchNotes}
                      onChange={handleChange("dispatchNotes")}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
              </div>
              {arr.length > 0 ? (
                // amount !== 0 &&
                // !isNaN(amount) && (
                <>
                  <div className="col-lg-12">
                    <div className="bg-white text-capitalize d-flex justify-content-between  border-bottom-grey"></div>
                    <div className="bg-white border-0 text-capitalize d-flex justify-content-between pt-3 px-2">
                      <h5 className="f-15 text-dark-grey mb-0">
                        <strong>Suggested Drivers:</strong>
                      </h5>
                    </div>
                  </div>
                  {arr.length > 0 &&
                    arr.map((c, i) => {
                      const routeTripD = c?.distanceMiles
                        ? (c?.distanceMiles?.toFixed(2) / 25)
                            .toString()
                            .split(".")
                        : 0.0;
                      const routeTripDHrs = routeTripD[0];
                      let routeTripDMin;
                      let routeTripDMinStr = "0." + routeTripD[1];
                      routeTripDMinStr = parseFloat(routeTripDMinStr);
                      routeTripDMin = routeTripDMinStr;
                      const routeTripD2 = routeTripDMin * 60;
                      const routeTripDMin2 = routeTripD2.toString().split(".");
                      let displayTime =
                        routeTripDHrs + "h " + routeTripDMin2[0] + "m";
                      return (
                        <div
                          className={`col-sm-${
                            12 / arr.length
                          } pl-4 border-right`}
                          key={i}
                        >
                          <div className=" my-3">
                            <div className="d-flex">
                              <div className="text-dark-grey ">
                                <div className="d-flex">
                                  <input
                                    type="checkbox"
                                    checked={selectedDriver === c.driverId}
                                    onChange={() =>
                                      handleCheckboxChange3(c.driverId)
                                    }
                                  />{" "}
                                  &nbsp;
                                  <strong>
                                    {c.user.firstName} {c.user.lastName}
                                  </strong>
                                  {/* Abdullah khan */}
                                </div>
                                {/* <br /> */}(<strong>D:</strong>{" "}
                                {c.distanceMiles.toFixed(2)} mi,{" "}
                                <strong>ETA:</strong> {displayTime}
                                {
                                  c.reservation && (
                                    <>
                                      , <strong>Dropoff:</strong>{" "}
                                      {` ${c.reservation.dropOffTime}`}
                                    </>
                                  )
                                  // `, Dropoff: ${c.reservation.dropOffTime}`
                                }
                                )
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                // )
                <></>
              )}
              <div className="col-sm-3 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Assign Driver
                        </label>
                      </div>
                      {/* <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div> */}
                    </div>
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={""}
                    value={availableDrivers
                      ?.map((x) => ({
                        label: `${x.firstName} ${x.lastName}`,
                        value: x.id,
                      }))
                      .find(
                        (customer) =>
                          customer.value ===
                          (values?.driverId?.id
                            ? values?.driverId?.id
                            : values?.driverId)
                      )}
                    isDisabled={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    // required={true}
                    name="pickUp"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        driverId: e.value,
                      });
                    }}
                    options={[
                      { label: "(Choose Driver)", value: "" },
                      ...availableDrivers?.map((x) => ({
                        label: `${x.firstName} ${x.lastName} `,
                        value: x.id,
                      })),
                    ]}
                  />
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-between">
                <div className="card-header bg-white border-0  text-capitalize d-flex justify-content-between ">
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    id="save-form"
                    disabled={loading}
                  >
                    {copyParam || returnTripParam ? "Create" : "Update"}
                    {/* Update */}
                  </button>
                </div>
                {/* <div className="card-header bg-white border-0  text-capitalize d-flex justify-content-between ">
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={onClickReturnTrip}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      Round Trip
                    </button>
                  </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
      {/* <AdminBookingAddClientModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        values={values}
        setValues={setValues}
      /> */}
      {showDropDown && (
        <AddStudentModal
          showDropDown={showDropDown}
          openDropDown={openDropDown}
          // setSelectedStudent={setSelectedStudent}
          values={values}
          setValues={setValues}
          booking
          // values={values}
          // setValues={setValues}
        />
      )}
      <AdminBookingPriceDetailModal
        showDetailDropDown={showDetailDropDown}
        openDetailDropDown={openDetailDropDown}
        fareDetails={fareDetails}
        useHardCodedFares={useHardCodedFares}
        toogleHardCoded={toogleHardCoded}
        hardCodedFares={hardCodedFares}
        handleHardCodedFares={handleHardCodedFares}
        handleUsePrecalculatedMilesTrue={handleUsePrecalculatedMilesTrue}
        handleUsePrecalculatedMilesFalse={handleUsePrecalculatedMilesFalse}
        recalculate={recalculate}
        useDirectFare={useDirectFare}
        toogleDirectFare={toogleDirectFare}
        directFare={directFare}
        handleDirectFare={handleDirectFare}
        fareDirectlyCalculate={fareDirectlyCalculate}
        useFlatDriverRate={useFlatDriverRate}
        toogleFlatDriverRate={toogleFlatDriverRate}
        flatDriverRate={flatDriverRate}
        handleFlatDriverRate={handleFlatDriverRate}
        flatDriverRateRecalculate={flatDriverRateRecalculate}
        handleVeryDirectFare={handleVeryDirectFare}
      />
      <AdminSettingVehicleAddNewServiceNameModal
        showDropDown={showDropDownVehicleService}
        openDropDown={openDropDownVehicleService}
        values={values}
        setValues={setValues}
        serviceNames={serviceNames}
        setServiceNames={setServiceNames}
      />

      {/* <AdminBookingAddDropPick showDropLoc={showDropLoc} openDropLoc={openDropLoc} values={values} stopArr={stopArr} setStopArr={setStopArr}/> */}
    </>
  ) : (
    <>
      <NotAllowed />
    </>
  );
};

export default AdminEditDispatching;
