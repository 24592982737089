/** @format */

import React, { useState, useEffect } from "react";
import AdminBookingTodayBooking from "../../components/adminComponents/adminBookingComponents/AdminBookingTodayBooking";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { navigateUserFunc } from "../../actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
import AdminOrderSearchTable from "../../components/adminComponents/adminDispatchingComponents/AdminOrderSearchTable";
// import * as XLSX from "xlsx";
import { tabUnassignedDispatching } from "../../actions/tabActions";
import { getAllStudents } from "../../actions/userActions";
import ExcelExport from "../../ExcelExport";
import moment from "moment";
import {
  getAllReservationV2,
  getTodayReservationsByCompanyId,
  getTodayReservationsByExternalCompanyId,
} from "../../actions/reservationAction";
import { notifySuccess } from "../../components/toast";

const ExternalCompanyBookingPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);

  const passengerData = async (e) => {
    const chicagoDateOptions = {
        timeZone: "America/Chicago",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
  
      const chicagoDate = new Date().toLocaleDateString(
        "en-US",
        chicagoDateOptions
      );
      const parts = chicagoDate.split("/");
      const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  
    dispatch(getTodayReservationsByExternalCompanyId({ companyId: auth.user.companyId.id, externalCompanyId: auth.user.externalCompanyId,today: formattedChicagoDate,
       }));
  };
  useEffect(() => {
    passengerData();
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
        <div class="px-4 py-2 border-top-0 emp-dashboard">
            <div class="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                <div id="table-actions" class="flex-grow-1 align-items-center">
                    <h4 class=" mb-0 f-21 text-capitalize font-weight-bold">Today Booking</h4>
                </div>
                {/* <div class="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3" role="group">
                    <div class="btn-group me-2" role="group" aria-label="First group">
                        <button type="button" class="btn btn-success" data-toggle="tooltip" data-original-title="Refresh">
                            <svg class="svg-inline--fa fa-sync fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path></svg>
                        </button>
                        <button type="button" class="btn btn-dark" data-toggle="tooltip" data-original-title="Filters" onclick="showHideDiv('divFilter')" data-bs-original-title="" title=""><svg class="svg-inline--fa fa-filter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="filter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path></svg></button>
                        <button type="button" class="btn btn-info" data-toggle="tooltip" data-original-title="Export Excel File"><svg class="svg-inline--fa fa-file-excel fa-w-12" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path></svg></button>
                    
                    </div> 
                </div> */}
                <div class="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3" role="group">

                <NavLink to="/booking" type="button" 
                // class="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0" 
                className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                id="add-lead">
                    <i className="fa fa-plus"> </i> Create 
                </NavLink>
            </div>
            </div>
            <div class="card-body p-0 bg-white b-shadow-4 rounded mb-3" id="divFilter">
                <div class="row p-2 px-4">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="f-14 text-dark mb-12" data-label="" for="clnumber">Driver Name</label>
                            <input type="text" class="form-control height-35 f-14" placeholder="" id="" autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="f-14 text-dark mb-12" data-label="" for="clnumber">Order ID</label>
                            <input type="text" class="form-control height-35 f-14" placeholder="" id="" autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="f-14 text-dark mb-12" data-label="" for="clnumber">Batch</label>
                            <input type="text" class="form-control height-35 f-14" placeholder="" id="" autocomplete="off"/>
                        </div>
                    </div>
                    <div class="col-sm-3 mt-3">
                        <div class="d-grid mt-3">
                            <button class="btn btn-success">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card ">
                <div class="d-flex border">
                    <div class="flex-grow-1">
                        <div class="dataTables_length d-flex" id="leads-table_length">
                            <div class="p-2">
                                <label>Show entries</label>
                            </div>
                            <label> 
                                <select name="leads-table_length" aria-controls="leads-table" class="custom-select custom-select-sm form-control form-control-sm">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select> 
                            </label>
                        </div>
                    </div>
                    <div class="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                        <form class="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0">
                            <div class="input-group bg-grey rounded">
                                <div class="input-group-prepend">
                                    <span class="input-group-text border-0 bg-additional-grey">
                                        <svg class="svg-inline--fa fa-search fa-w-16 f-13 text-dark-grey" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                                    </span>
                                </div>
                                <input type="text" class="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search" autocomplete="off"/>
                            </div>
                        </form>
                    </div>

                </div>
                <div class="card-body p-2">
                
                    <div class="invoice-item invoice-table-wrap ">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table table-responsive">
                                    <table class="invoice-table table border table-center mb-0 ">
                                        <thead class="card-header">
                                        <tr>
                                            <th class=" border-right">Order Id</th>
                                            <th class=" border-right">Pick-Up Date</th>
                                            <th class=" border-right">Pick-Up Time</th>
                                            <th class=" border-right">Pickup - Drop Off</th>
                                            <th class=" border-right">Number of Passengers</th>
                                            {/* <th class=" border-right">Luggage Count</th> */}
                                            <th class=" border-right"> Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {reservation &&
                        reservation?.reservation &&
                        reservation?.reservation?.today &&
                        reservation?.reservation?.today.length > 0 &&
                        reservation?.reservation?.today.map((current, i) => {
                          return (
                            <tr>
                              <td class=" border-right">
                                  {current.orderId}
                              </td>
                                                <td class=" border-right">
                                                {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}
                                                </td>
                                                <td class=" border-right">{parseInt(current.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.pickUpTime.substring(0, 2)) > 12
                    ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                    : parseInt(current.pickUpTime.substring(0, 2))}
                  {":" + current.pickUpTime.substring(3, 5)}
                  {parseInt(current?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}</td>
                                                
                                                <td class=" border-right">
                                                <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.pickUpTime.substring(0, 2)) > 12
                    ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                    : parseInt(current.pickUpTime.substring(0, 2))}
                  {":" + current.pickUpTime.substring(3, 5)}
                  {parseInt(current?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>{current?.distanceFromPickToDropOff?.toFixed(2)} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.dropOffDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.dropOffTime.substring(0, 2)) > 12
                    ? parseInt(current.dropOffTime.substring(0, 2)) - 12
                    : parseInt(current.dropOffTime.substring(0, 2))}
                  {":" + current.dropOffTime.substring(3, 5)}
                  {parseInt(current.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
                                                </td>
                                                <td class=" border-right">
                                                    {current.numberOfPassenger}
                                                </td>
                                                {/* <td class=" border-right">
                                                {current.luggageCount}
                                                </td> */}
                                                <td class=" border-right">
                                                    {current.status}
                                                </td>
                                            </tr>
                          )})}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="card-footer">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <div class="dataTables_info" id="leads-table_info" role="status" aria-live="polite">Showing 1 to 5 of 10 entries</div>
                        </div>
                        <div>
                            <div class="dataTables_paginate paging_simple_numbers" id="leads-table_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous disabled" id="leads-table_previous">
                                        <a href="#" aria-controls="leads-table" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                    </li>
                                    <li class="paginate_button page-item active"><a href="#" aria-controls="leads-table" data-dt-idx="1" tabindex="0" class="page-link">1</a>
                                    </li>
                                    <li class="paginate_button page-item "><a href="#" aria-controls="leads-table" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                                    </li>
                                    <li class="paginate_button page-item next" id="leads-table_next"><a href="#" aria-controls="leads-table" data-dt-idx="3" tabindex="0" class="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
      </div>

      {/* <UploadFileModal /> */}
    </>
  );
};
export default ExternalCompanyBookingPage;
