/** @format */

import axios from "axios";
import {
  RESERVATION_VIEW_REQUEST,
  RESERVATION_VIEW_SUCCESS,
  RESERVATION_VIEW_FAIL,
  RESERVATION_PAST_VIEW_REQUEST,
  RESERVATION_PAST_VIEW_SUCCESS,
  RESERVATION_PAST_VIEW_FAIL,
  RESERVATION_FUTURE_VIEW_REQUEST,
  RESERVATION_FUTURE_VIEW_SUCCESS,
  RESERVATION_FUTURE_VIEW_FAIL,
  RESERVATION_TODAY_VIEW_REQUEST,
  RESERVATION_TODAY_VIEW_SUCCESS,
  RESERVATION_TODAY_VIEW_FAIL,
  RESERVATION_VIEW_REQUEST_V2,
  RESERVATION_VIEW_SUCCESS_V2,
  RESERVATION_VIEW_FAIL_V2,
  RESERVATION_CREATE_REQUEST,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_CREATE_FAIL,
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  RESERVATION_UPDATE_REQUEST_ST,
  RESERVATION_UPDATE_SUCCESS_ST,
  RESERVATION_UPDATE_FAIL_ST,
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAIL,
  DRIVER_RESERVATION_REQUEST,
  DRIVER_RESERVATION_SUCCESS,
  DRIVER_RESERVATION_FAIL,
  RESERVATION_VIEW_SCHEDULE_REQUEST,
  RESERVATION_VIEW_SCHEDULE_SUCCESS,
  RESERVATION_VIEW_SCHEDULE_FAIL,
  RESERVATION_VIEW_SCHEDULE_REQUEST_V2,
  RESERVATION_VIEW_SCHEDULE_SUCCESS_V2,
  RESERVATION_VIEW_SCHEDULE_FAIL_V2,
  RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
  RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
  RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
  RESERVATION_ORDERS_SEARCH_REQUEST,
  RESERVATION_ORDERS_SEARCH_SUCCESS,
  RESERVATION_ORDERS_SEARCH_FAIL,
  RESERVATION_ORDERS_SEARCH_REQUEST_V2,
  RESERVATION_ORDERS_SEARCH_SUCCESS_V2,
  RESERVATION_ORDERS_SEARCH_FAIL_V2,
  DRIVER_EARNING_REQUEST,
  DRIVER_EARNING_SUCCESS,
  DRIVER_EARNING_FAIL,
  RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
  RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
  DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
  DRIVER_TODAY_RESERVATION_VIEW_FAIL,
  DRIVER_RIDE_UPDATE_REQUEST,
  DRIVER_RIDE_UPDATE_SUCCESS,
  DRIVER_RIDE_UPDATE_FAIL,
  DRIVER_PAST_RESERVATION_VIEW_REQUEST,
  DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
  DRIVER_PAST_RESERVATION_VIEW_FAIL,
  RESERVATION_COMPLETE_DELETE_REQUEST,
  RESERVATION_COMPLETE_DELETE_SUCCESS,
  RESERVATION_COMPLETE_DELETE_FAIL,
  RESERVATION_REQUEST,
  RESERVATION_SUCCESS,
  RESERVATION_FAIL,
  SERVERADDRESS,
} from "../constants";
import { notifyFailure, notifySuccess } from "../components/toast";

// ALL RESERVATION BOOKING DISPLAY VIEW
export const getAllReservation = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationsByCompanyId`,
      obj
    );
    localStorage.setItem("reservation", JSON.stringify(data));
    localStorage.setItem("today", JSON.stringify(data.today));
    localStorage.setItem("previous", JSON.stringify(data.previous));
    dispatch({
      type: RESERVATION_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL RESERVATION BOOKING DISPLAY VIEW
export const getAllReservationV2 = (obj, callback) => async (dispatch) => {
  dispatch({
    type: RESERVATION_VIEW_REQUEST_V2,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v2/reservation/getReservationsByCompanyId`,
      obj
    );
    const modifiedData = {
      today: data.today.slice(0, 200),
      previous: data.previous.slice(0, 200),
      future: data.future.slice(0, 200),
    };
    localStorage.setItem("reservation", JSON.stringify(modifiedData));
    // localStorage.setItem("today", JSON.stringify(data.today));
    // localStorage.setItem("previous", JSON.stringify(data.previous));
    dispatch({
      type: RESERVATION_VIEW_SUCCESS_V2,
      payload: modifiedData,
    });
    callback && callback(data);
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_VIEW_FAIL_V2,
      payload: err,
    });
  }
};

// ALL PAST RESERVATION BOOKING DISPLAY VIEW
export const getPastReservationsByCompanyId =
  (obj, callback) => async (dispatch) => {
    dispatch({
      type: RESERVATION_PAST_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v2/reservation/getPastReservationsByCompanyId`,
        obj
      );
      // const previous = {
      //   previous: data.slice(0, 200),
      // };
      localStorage.setItem("previous", JSON.stringify(data.slice(0, 200)));
      dispatch({
        type: RESERVATION_PAST_VIEW_SUCCESS,
        payload: data.slice(0, 200),
      });
      callback && callback(data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_PAST_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL PAST RESERVATION BOOKING DISPLAY VIEW PAGINATION FROM BACKEND
export const getPastReservationsByCompanyIdV3 =
  (obj, callback) => async (dispatch) => {
    dispatch({
      type: RESERVATION_PAST_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v3/reservation/getPastReservationsByCompanyId`,
        obj
      );
      localStorage.setItem("previous", JSON.stringify(data.results));
      dispatch({
        type: RESERVATION_PAST_VIEW_SUCCESS,
        payload: data,
      });
      callback && callback(data.results);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_PAST_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL FUTURE RESERVATION BOOKING DISPLAY VIEW
export const getFutureReservationsByCompanyId =
  (obj, callback) => async (dispatch) => {
    dispatch({
      type: RESERVATION_FUTURE_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v2/reservation/getFutureReservationsByCompanyId`,
        obj
      );
      // const future = {
      //   future: data.slice(0, 200),
      // };
      localStorage.setItem("future", JSON.stringify(data.slice(0, 200)));
      dispatch({
        type: RESERVATION_FUTURE_VIEW_SUCCESS,
        payload: data.slice(0, 200),
      });
      callback && callback(data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_FUTURE_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL TODAY RESERVATION BOOKING DISPLAY VIEW
export const getTodayReservationsByCompanyId =
  (obj, callback) => async (dispatch) => {
    dispatch({
      type: RESERVATION_TODAY_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v2/reservation/getTodayReservationsByCompanyId`,
        obj
      );
      // const today = {
      //   today: data.slice(0, 200),
      // };
      localStorage.setItem("today", JSON.stringify(data.slice(0, 200)));
      dispatch({
        type: RESERVATION_TODAY_VIEW_SUCCESS,
        payload: data.slice(0, 200),
      });
      callback && callback(data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_TODAY_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL TODAY RESERVATION BOOKING DISPLAY VIEW FOR EXTERNAL
export const getTodayReservationsByExternalCompanyId =
  (obj, callback) => async (dispatch) => {
    dispatch({
      type: RESERVATION_TODAY_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/reservation/getTodayReservationsByExternalCompanyId`,
        obj
      );
      // const today = {
      //   today: data.slice(0, 200),
      // };
      localStorage.setItem("today", JSON.stringify(data.slice(0, 200)));
      dispatch({
        type: RESERVATION_TODAY_VIEW_SUCCESS,
        payload: data.slice(0, 200),
      });
      callback && callback(data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_TODAY_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ADD NEW RESERVATION
export const addReservation =
  (obj, callback, setReservationId) => async (dispatch) => {
    dispatch({
      type: RESERVATION_CREATE_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/reservation/createReservation`,
        obj
      );
      dispatch({
        type: RESERVATION_CREATE_SUCCESS,
        payload: data,
      });
      notifySuccess("Reservation Created");
      // console.log(data.id, "returnReservationData")
      // const resId = data.id
      // setReservationId({id:resId})
      // navigate('/requested-orders')
      callback && callback(data.id);
      // console.log(navigateDispatching(),"chal toh raha hn 2")
    } catch (err) {
      notifyFailure(err.response.data.message);
      // console.log(err);
      dispatch({
        type: RESERVATION_CREATE_FAIL,
        payload: err,
      });
    }
  };

// RESERVATION UPDATE BY ID
export const updateReservation =
  (obj, navigateDispatching, notShowMsg) => async (dispatch) => {
    dispatch({
      type: RESERVATION_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/reservation/updateReservation`,
        obj
      );
      if (!notShowMsg) {
        notifySuccess("Reservation Updated Successfully !");
      }
      dispatch({
        type: RESERVATION_UPDATE_SUCCESS,
        payload: data,
      });
      if (navigateDispatching) {
        navigateDispatching();
      }
    } catch (err) {
      console.log(err);
      notifyFailure(err.response.data.message);
      dispatch({
        type: RESERVATION_UPDATE_FAIL,
        payload: err,
      });
    }
  };

// RESERVATION UPDATE BY ID
export const updateReservation2 = (obj, successCB) => async (dispatch) => {
  dispatch({
    type: RESERVATION_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservation/updateReservation`,
      obj
    );
    dispatch({
      type: RESERVATION_UPDATE_SUCCESS,
      payload: data,
    });
    // console.log(data, successCB, "data");
    if (successCB) {
      successCB(data);
    }
    notifySuccess("Reservation Updated Successfully !");
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: RESERVATION_UPDATE_FAIL,
      payload: err,
    });
  }
};

export const updateDriverReservationStatus = (obj, cb) => async (dispatch) => {
  dispatch({
    type: RESERVATION_UPDATE_REQUEST_ST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservationStatus/updateReservationDriverStatus`,
      obj
    );
    notifySuccess("Reservation Updated Successfully !");
    dispatch({
      type: RESERVATION_UPDATE_SUCCESS_ST,
      payload: data,
    });
    if (cb) {
      cb();
    }
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: RESERVATION_UPDATE_FAIL_ST,
      payload: err,
    });
  }
};

// RESERVATION DELETE BY ID
export const deleteReservation = (obj, successCB) => async (dispatch) => {
  dispatch({
    type: RESERVATION_DELETE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservation/updateReservation`,
      obj
    );
    dispatch({
      type: RESERVATION_DELETE_SUCCESS,
      payload: data,
    });
    if (successCB) {
      successCB(data);
    }
    notifySuccess("Reservation Cancelled");
  } catch (err) {
    notifyFailure(err);
    // console.log(err);
    dispatch({
      type: RESERVATION_DELETE_FAIL,
      payload: err,
    });
  }
};
// COMPLETE RESERVATION DELETE BY ID
export const deleteReservationCompletely =
  (obj, successCB) => async (dispatch) => {
    dispatch({
      type: RESERVATION_COMPLETE_DELETE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/reservation/updateReservation`,
        obj
      );
      dispatch({
        type: RESERVATION_COMPLETE_DELETE_SUCCESS,
        payload: data,
      });
      if (successCB) {
        successCB(data);
      }
      notifySuccess("Reservation Deleted");
    } catch (err) {
      notifyFailure(err);
      // console.log(err);
      dispatch({
        type: RESERVATION_COMPLETE_DELETE_FAIL,
        payload: err,
      });
    }
  };

// ALL RESERVATION OF A DRIVER
// export const getReservationsByUserId = (obj) => async (dispatch) => {
//     dispatch({
//         type: DRIVER_RESERVATION_REQUEST,
//     });
//     try {
//         const { data } = await axios.post(`${SERVERADDRESS}/v1/reservation/getReservationsByUserId`, obj);
//         localStorage.setItem("newRequest", JSON.stringify(data.newRequest));
//         localStorage.setItem("history", JSON.stringify(data.history));
//         dispatch({
//             type: DRIVER_RESERVATION_SUCCESS,
//             payload: data,
//         });
//     }
//     catch (err) {
//         console.log(err)
//         dispatch({
//             type: DRIVER_RESERVATION_FAIL,
//             payload: err,
//         });
//     }
// };
export const getReservationsByUserId = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_RESERVATION_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationsByUserId`,
      obj
    );
    localStorage.setItem("reservationRequest", JSON.stringify(data));
    dispatch({
      type: DRIVER_RESERVATION_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_RESERVATION_FAIL,
      payload: err,
    });
  }
};

export const getPastReservationsByUserId = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_PAST_RESERVATION_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getPastReservationsByUserId`,
      obj
    );
    localStorage.setItem("pastHistory", JSON.stringify(data));
    dispatch({
      type: DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_PAST_RESERVATION_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL RESERVATION SCHEDULE
export const getReservationsSchedule = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_VIEW_SCHEDULE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationsSchedule`,
      obj
    );
    localStorage.setItem("reservationsSchedule", JSON.stringify(data));
    localStorage.setItem("assigned", JSON.stringify(data.assigned));
    localStorage.setItem("unassigned", JSON.stringify(data.unassigned));
    localStorage.setItem("pending", JSON.stringify(data.pending));
    dispatch({
      type: RESERVATION_VIEW_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_VIEW_SCHEDULE_FAIL,
      payload: err,
    });
  }
};

// ALL RESERVATION SCHEDULE V2
export const getReservationsScheduleV2 = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_VIEW_SCHEDULE_REQUEST_V2,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v2/reservation/getReservationsSchedule`,
      obj
    );
    localStorage.setItem("reservationsSchedule", JSON.stringify(data));
    localStorage.setItem("assigned", JSON.stringify(data.assigned));
    localStorage.setItem("unassigned", JSON.stringify(data.unassigned));
    localStorage.setItem("pending", JSON.stringify(data.pending));
    dispatch({
      type: RESERVATION_VIEW_SCHEDULE_SUCCESS_V2,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_VIEW_SCHEDULE_FAIL_V2,
      payload: err,
    });
  }
};

export const setNewRequest = (obj) => (dispatch) => {
  dispatch({
    type: "SET_NEWREQUEST",
    payload: obj,
  });
};

// RESERVATION STATUS UPDATE BY RESERVATION ID
export const updateReservationDriverStatus = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservationStatus/updateReservationDriverStatus`,
      obj
    );
    dispatch({
      type: RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
      payload: err,
    });
  }
};

export const setReservations = (obj) => (dispatch) => {
  // console.log("redux actions", obj);
  dispatch({
    type: "SET_RESERVATIONS",
    payload: obj,
  });
};
// GET DRIVER TODAY BOOKING VIEW
export const getTodayReservationsByUserId = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getTodayReservationsByUserId`,
      obj
    );
    localStorage.setItem("todayReservationView", JSON.stringify(data));
    dispatch({
      type: DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_TODAY_RESERVATION_VIEW_FAIL,
      payload: err,
    });
  }
};

// UPDATE TRIP
export const updateTrip = (obj, cb) => async (dispatch) => {
  dispatch({
    type: DRIVER_RIDE_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/trip/updateTrip`,
      obj
    );
    dispatch({
      type: DRIVER_RIDE_UPDATE_SUCCESS,
      payload: data,
    });
    if (cb) {
      cb();
    }
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_RIDE_UPDATE_FAIL,
      payload: err,
    });
  }
};

export const markedLocation = (obj) => (dispatch) => {
  // console.log("redux actions", obj )
  dispatch({
    type: "MARKED_LOCATION",
    payload: obj,
  });
};

// ALL RESERVATION BOOKING DISPLAY VIEW
export const getAllReservationDriverStatusByUserId =
  (obj) => async (dispatch) => {
    dispatch({
      type: RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/reservationStatus/getAllReservationDriverStatusByUserId`,
        obj
      );
      localStorage.setItem("userNotificationList", JSON.stringify(data));
      // localStorage.setItem("reservationRequest", JSON.stringify(data));
      dispatch({
        type: RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({
        type: RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
        payload: err,
      });
    }
  };

// DRIVER EARNING
export const driverEarning = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_EARNING_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/driverEarning`,
      obj
    );
    localStorage.setItem("driverEarning", JSON.stringify(data));
    dispatch({
      type: DRIVER_EARNING_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: DRIVER_EARNING_FAIL,
      payload: err,
    });
  }
};

// ADMIN RESERVATION SEARCH ORDERS
export const getReservationsByDateV2 = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_ORDERS_SEARCH_REQUEST_V2,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v2/reservation/getReservationsByDate`,
      obj
    );
    // localStorage.setItem("getReservationsByDate", JSON.stringify(data));
    dispatch({
      type: RESERVATION_ORDERS_SEARCH_SUCCESS_V2,
      payload: data,
    });
    // arrReassign()
  } catch (err) {
    console.log(err);
    dispatch({
      type: RESERVATION_ORDERS_SEARCH_FAIL_V2,
      payload: err,
    });
  }
};

// ADMIN RESERVATION SEARCH ORDERS
export const getReservationsByDate = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_ORDERS_SEARCH_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationsByDate`,
      obj
    );
    localStorage.setItem("getReservationsByDate", JSON.stringify(data));
    dispatch({
      type: RESERVATION_ORDERS_SEARCH_SUCCESS,
      payload: data,
    });
    // arrReassign()
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_ORDERS_SEARCH_FAIL,
      payload: err,
    });
  }
};

// ADMIN SEARCH ORDERS FROM TO
export const fromTo = (obj) => async (dispatch) => {
  dispatch({
    type: "SELECT_DATE",
    payload: obj,
  });
};

// GET RESERVATION BY ID
export const getReservationById = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/getReservationById`,
      obj
    );
    dispatch({
      type: RESERVATION_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: RESERVATION_FAIL,
      payload: err,
    });
  }
};

// GET RESERVATION BY ID
export const setReservationData = (obj) => async (dispatch) => {
  dispatch({
    type: RESERVATION_SUCCESS,
    payload: obj,
  });
};

// Hardcoded actions for arrived, pick, dropoff
export const undoStartAction = (obj) => {
  return {
    type: "UNDO_START_ACTION",
    payload: obj,
  };
};

export const undoInprogressAction = (obj) => {
  return {
    type: "UNDO_ARRIVED_ACTION",
    payload: obj,
  };
};

// RESERVATION UPDATE BY ID
export const undoCancelReservation = (obj, successCB) => async (dispatch) => {
  dispatch({
    type: RESERVATION_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservation/undoCancelReservation`,
      obj
    );
    dispatch({
      type: RESERVATION_UPDATE_SUCCESS,
      payload: data,
    });
    // console.log(data, successCB, "data");
    if (successCB) {
      successCB(data);
    }
    notifySuccess("Reservation Updated Successfully !");
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: RESERVATION_UPDATE_FAIL,
      payload: err,
    });
  }
};

// RESERVATION UPDATE BY ID
export const updateDispatcherWorking = (obj, successCB) => async (dispatch) => {
  dispatch({
    type: RESERVATION_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/updateDispatcherWorking`,
      obj
    );
    dispatch({
      type: RESERVATION_UPDATE_SUCCESS,
      payload: data,
    });
    // console.log(data, successCB, "data");
    if (successCB) {
      successCB(data);
    }
    if (obj.dispatcherWorking === "") {
      notifySuccess("Unmarked Working!");
    } else {
      notifySuccess("Marked Working!");
    }
  } catch (err) {
    console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: RESERVATION_UPDATE_FAIL,
      payload: err,
    });
  }
};
