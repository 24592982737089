/** @format */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  tabBasicReportRow,
  tabCalculatorReportRow,
  tabDriverRouteTripDetail,
  tabRouteDistanceReport,
} from "../../../actions/tabActions";
import AdminBasicReportTable from "./AdminBasicReportTable";
import AdminCalculatorReport from "./AdminCalculatorReport";
import AdminReportSearchandFilter from "./AdminReportSearchandFilterV2";
import AdminReportSearchTable from "./AdminReportSearchTable";
import AdminDriverRouteTripReportSearchandFilter from "./AdminDriverRouteTripReportSearchandFilter";
import AdminDriverRouteTripReportTable from "./AdminDriverRouteTripReportTable";
import AdminRouteDistanceReport from "./AdminRouteDistanceReport";

const AdminReportViewV2 = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();

  // min width 992px hai toh

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="card-body">
          <div className="row">
            {(auth?.user?.permissionId?.booking?.isAllow === true ||
              auth?.user?.permissionId?.routes?.isAllow === true) && (
              <div className="col-md-6">
                <div className="d-flex align-items-center ">
                  <div className="mr-3">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="Icon__Image-sc-cstg0k-0 gGURka"
                      size="32"
                    >
                      <path
                        opacity="0.2"
                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                        fill="#48DDFF"
                      ></path>
                      <circle
                        cx="10.4897"
                        cy="17.5103"
                        r="3.26392"
                        fill="#48B6FF"
                      ></circle>
                      <circle
                        cx="10.4897"
                        cy="10.4897"
                        r="3.26392"
                        fill="#F9267C"
                      ></circle>
                      <circle
                        cx="17.3877"
                        cy="17.5103"
                        r="3.26392"
                        fill="#48DDFF"
                      ></circle>
                    </svg>
                  </div>
                  <div>
                    <p className="mb-0 f-18 font-weight-bold text-gray-900 ">
                      {" "}
                      Booking Reports
                    </p>
                  </div>
                </div>
                <div className="lint-text my-3">
                  <ul>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/daily-activity-report" className="ml-2">
                        Daily Activity Report
                      </Link>
                    </li>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>
                      <Link to="/load-no-analysis-report" className="ml-2">
                        Load # Analysis Report
                      </Link>
                    </li>
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Booking Summary Report
                      </Link>
                    </li> */}
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Today’s Trips Reports
                      </Link>
                    </li> */}
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Upcoming Trips Reports
                      </Link>
                    </li> */}
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Past Trips Reports
                      </Link>
                    </li> */}
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Drivers Reports
                      </Link>
                    </li> */}
                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Graphic Analytical Report
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="d-flex align-items-center ">
                  <div className="mr-3">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="Icon__Image-sc-cstg0k-0 gGURka"
                      size="32"
                    >
                      <path
                        d="M15.546 6.28236L12.4952 7.43551C11.6598 7.7513 11.6259 8.92057 12.4416 9.28425L19.1984 12.2967C19.8546 12.5892 20.5964 12.1151 20.6152 11.397C20.6266 10.959 20.6408 10.5338 20.6572 10.2421C20.6913 9.63645 20.3199 9.33998 20.0717 9.24139C20.0199 9.22081 19.9667 9.20379 19.9158 9.18111L16.2479 7.54675C16.0064 7.43914 15.9177 7.14153 16.0609 6.91929C16.2862 6.56977 15.935 6.13533 15.546 6.28236Z"
                        fill="#00CC6B"
                      ></path>
                      <path
                        d="M12.4539 21.8143L15.5047 20.6612C16.3401 20.3454 16.3741 19.1761 15.5583 18.8124L8.8015 15.8C8.14539 15.5074 7.40353 15.9816 7.38476 16.6997C7.37331 17.1377 7.35914 17.5629 7.34272 17.8546C7.30862 18.4602 7.68008 18.7567 7.92825 18.8553C7.98004 18.8759 8.03319 18.8929 8.0841 18.9156L11.7521 20.5499C11.9936 20.6575 12.0823 20.9551 11.939 21.1774C11.7138 21.5269 12.065 21.9613 12.4539 21.8143Z"
                        fill="#00CC6B"
                      ></path>
                      <path
                        d="M19.9424 14.9593L8.96754 9.72968C8.31445 9.41848 7.55724 9.88229 7.53773 10.6055L7.49685 12.1214C7.47939 12.7687 7.86371 13.0814 8.12885 13.1882C8.19496 13.2149 8.26318 13.2366 8.32752 13.2672L18.9918 18.349C19.6545 18.6647 20.4212 18.1827 20.4385 17.4488C20.4506 16.9337 20.469 16.4363 20.4961 16.1332C20.5544 15.4816 20.1513 15.0791 19.9424 14.9593Z"
                        fill="#148574"
                      ></path>
                      <circle
                        opacity="0.2"
                        cx="14"
                        cy="14"
                        r="14"
                        fill="#35B14F"
                      ></circle>
                    </svg>
                  </div>
                  <div>
                    <p className="mb-0 f-18 font-weight-bold text-gray-900">
                      {" "}
                      Route Reports
                    </p>
                  </div>
                </div>
                <div className="lint-text my-3">
                  <ul>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/route-daily-report" className="ml-2">
                        Route Daily Report
                      </Link>
                    </li>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/route-summary-report" className="ml-2">
                        Route Summary Report
                      </Link>
                    </li>

                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/route-calculator-report" className="ml-2">
                        Route Calculator Report
                      </Link>
                    </li>

                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/driver-route-trip-report" className="ml-2">
                        Driver Route Trip Report
                      </Link>
                    </li>

                    {/* <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="#" className="ml-2">
                        Route Distance Report
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            )}
            {auth?.user?.permissionId?.accounting?.isAllow === true && (
              <div className="col-md-6">
                <div className="d-flex align-items-center ">
                  <div className="mr-3">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="Icon__Image-sc-cstg0k-0 gGURka"
                      size="32"
                    >
                      <path
                        opacity="0.2"
                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                        fill="#1990FD"
                      ></path>
                      <circle
                        cx="7.18696"
                        cy="16.7019"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="9.36714"
                        cy="13.9768"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="12.0924"
                        cy="11.7966"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="14.2726"
                        cy="13.9768"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="16.4527"
                        cy="16.7019"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="18.6331"
                        cy="13.9768"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                      <circle
                        cx="20.8132"
                        cy="11.2515"
                        r="1.0901"
                        fill="#1990FD"
                      ></circle>
                    </svg>
                  </div>
                  <div>
                    <p className="mb-0 f-18 font-weight-bold text-gray-900">
                      {" "}
                      Accounting Reports
                    </p>
                  </div>
                </div>
                <div className="lint-text my-3">
                  <ul>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/driver-settlement-report" className="ml-2">
                        Driver Settlement Report
                      </Link>
                    </li>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/percentage-report" className="ml-2">
                        Percentage Report
                      </Link>
                    </li>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link
                        to="/annually-driver-earnings-report"
                        className="ml-2"
                      >
                        Annually Driver Earnings Report
                      </Link>
                    </li>
                    <li className="mb-1">
                      <i
                        className="fa fa-circle"
                        style={{ fontSize: "8px" }}
                      ></i>

                      <Link to="/profit-&-loss-report" className="ml-2">
                        Profit &amp; Loss Report
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminReportViewV2;
