/** @format */

// BACKEND SERVER
// export const SERVERADDRESS = "http://localhost:5000";
// export const SOCKET_SERVER_ADDRESS = "http://localhost:5000";
// // export const SERVERADDRESS = "/api";
// export const SERVERADDRESS =
//   "https://connectcaretrans-test-backend-a7e4da8d52da.herokuapp.com";
// export const SOCKET_SERVER_ADDRESS =
//   "https://connectcaretrans-test-sockets-4a091d4e06bd.herokuapp.com";

// env options
// local or production
export const ENVIRONMENT = "production"; //
// export const ENVIRONMENT2 = "localhost"; // localhost or website

export const SERVERADDRESS =
  ENVIRONMENT === "production"
    ? "https://connectcaretrans-backend-8a7b18191277.herokuapp.com"
    : ENVIRONMENT === "local"
    ? "https://connectcaretrans-test-backend-a7e4da8d52da.herokuapp.com"
    : "http://localhost:5000";
export const SOCKET_SERVER_ADDRESS =
  ENVIRONMENT === "production"
    ? "https://connectcaretrans-sockets-9a129cb3f553.herokuapp.com"
    : ENVIRONMENT === "local"
    ? "https://connectcaretrans-test-sockets-4a091d4e06bd.herokuapp.com"
    : "http://localhost:5000";
// export const SOCKET_SERVER_ADDRESS = "http://localhost:5006";
// export const SERVERADDRESS = "http://api.connectcaretrans.net";

// // Storage Bucket
// export const BUCKET_BASE_URL =
//   "https://ridetifyportal.s3.amazonaws.com/";
// Storage Bucket
export const BUCKET_BASE_URL =
  "https://pub-7e87be927e7b48cc8c26e1656ea439fe.r2.dev/";

export const BUCKET_ENDPOINT =
  "https://5aa5d988e1bde0d278ab9f851dccfa85.r2.cloudflarestorage.com";
export const BUCKET_NAME = "cctportal";
export const BUCKET_REGION = "auto";
export const BUCKET_ACCESS_KEY_ID = "b66fb2c2c143b626a436ec1caa808431";
export const BUCKET_SECRET_KEY =
  "7b0b2f0e36f69ed390c425f173c334dab1ef8b0d4fa7f193555f059febf91cea";

// USER LOGIN CONST
export const USER_AUTH_REQUEST = "USER_AUTH_REQUEST";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAIL = "USER_AUTH_FAIL";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const COMPANY_AUTH_REQUEST = "COMPANY_AUTH_REQUEST";
export const COMPANY_AUTH_SUCCESS = "COMPANY_AUTH_SUCCESS";
export const COMPANY_AUTH_FAIL = "COMPANY_AUTH_FAIL";
export const FORGOT_PASSWORD_CREATE_REQUEST = "FORGOT_PASSWORD_CREATE_REQUEST";
export const FORGOT_PASSWORD_CREATE_SUCCESS = "FORGOT_PASSWORD_CREATE_SUCCESS";
export const FORGOT_PASSWORD_CREATE_FAIL = "FORGOT_PASSWORD_CREATE_FAIL";
export const RESET_PASSWORD_CREATE_REQUEST = "RESET_PASSWORD_CREATE_REQUEST";
export const RESET_PASSWORD_CREATE_SUCCESS = "RESET_PASSWORD_CREATE_SUCCESS";
export const RESET_PASSWORD_CREATE_FAIL = "RESET_PASSWORD_CREATE_FAIL";
export const SEND_VERIFICATION_EMAIL_REQUEST =
  "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_FAIL = "SEND_VERIFICATION_EMAIL_FAIL";
export const GET_TOKEN_CREATE_REQUEST = "GET_TOKEN_CREATE_REQUEST";
export const GET_TOKEN_CREATE_SUCCESS = "GET_TOKEN_CREATE_SUCCESS";
export const GET_TOKEN_CREATE_FAIL = "GET_TOKEN_CREATE_FAIL";
export const USER_LOGIN_INFO_CREATE_REQUEST = "USER_LOGIN_INFO_CREATE_REQUEST";
export const USER_LOGIN_INFO_CREATE_SUCCESS = "USER_LOGIN_INFO_CREATE_SUCCESS";
export const USER_LOGIN_INFO_CREATE_FAIL = "USER_LOGIN_INFO_CREATE_FAIL";
export const UPDATE_USER_LOGIN_INFO_REQUEST = "UPDATE_USER_LOGIN_INFO_REQUEST";
export const UPDATE_USER_LOGIN_INFO_SUCCESS = "UPDATE_USER_LOGIN_INFO_SUCCESS";
export const UPDATE_USER_LOGIN_INFO_FAIL = "UPDATE_USER_LOGIN_INFO_FAIL";
export const ALL_EMPLOYEES_DATA_REQUEST = "ALL_EMPLOYEES_DATA_REQUEST";
export const ALL_EMPLOYEES_DATA_SUCCESS = "ALL_EMPLOYEES_DATA_SUCCESS";
export const ALL_EMPLOYEES_DATA_FAIL = "ALL_EMPLOYEES_DATA_FAIL";
export const ONLINE_USERS_DATA_REQUEST = "ONLINE_USERS_DATA_REQUEST";
export const ONLINE_USERS_DATA_SUCCESS = "ONLINE_USERS_DATA_SUCCESS";
export const ONLINE_USERS_DATA_FAIL = "ONLINE_USERS_DATA_FAIL";
export const USER_LOGIN_ACTIVITY_VIEW_REQUEST =
  "USER_LOGIN_ACTIVITY_VIEW_REQUEST";
export const USER_LOGIN_ACTIVITY_VIEW_SUCCESS =
  "USER_LOGIN_ACTIVITY_VIEW_SUCCESS";
export const USER_LOGIN_ACTIVITY_VIEW_FAIL = "USER_LOGIN_ACTIVITY_VIEW_FAIL";
export const VERIFY_PIN_REQUEST = "VERIFY_PIN_REQUEST";
export const VERIFY_PIN_SUCCESS = "VERIFY_PIN_SUCCESS";
export const VERIFY_PIN_FAIL = "VERIFY_PIN_FAIL";

// USER CONST
export const USER_VIEW_REQUEST = "USER_VIEW_REQUEST";
export const USER_VIEW_SUCCESS = "USER_VIEW_SUCCESS";
export const USER_VIEW_FAIL = "USER_VIEW_FAIL";

export const USER_VIEW_REQUEST_V2 = "USER_VIEW_REQUEST_V2";
export const USER_VIEW_SUCCESS_V2 = "USER_VIEW_SUCCESS_V2";
export const USER_VIEW_FAIL_V2 = "USER_VIEW_FAIL_V2";

export const INACTIVE_USER_VIEW_REQUEST = "INACTIVE_USER_VIEW_REQUEST";
export const INACTIVE_USER_VIEW_SUCCESS = "INACTIVE_USER_VIEW_SUCCESS";
export const INACTIVE_USER_VIEW_FAIL = "INACTIVE_USER_VIEW_FAIL";
export const ALL_ACTIVE_USER_VIEW_REQUEST = "ALL_ACTIVE_USER_VIEW_REQUEST";
export const ALL_ACTIVE_USER_VIEW_SUCCESS = "ALL_ACTIVE_USER_VIEW_SUCCESS";
export const ALL_ACTIVE_USER_VIEW_FAIL = "ALL_ACTIVE_USER_VIEW_FAIL";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const DRIVER_VIEW_REQUEST = "DRIVER_VIEW_REQUEST";
export const DRIVER_VIEW_SUCCESS = "DRIVER_VIEW_SUCCESS";
export const DRIVER_VIEW_FAIL = "DRIVER_VIEW_FAIL";

export const DRIVER_CREATE_REQUEST = "DRIVER_CREATE_REQUEST";
export const DRIVER_CREATE_SUCCESS = "DRIVER_CREATE_SUCCESS";
export const DRIVER_CREATE_FAIL = "DRIVER_CREATE_FAIL";

export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";

export const CLIENT_VIEW_REQUEST = "CLIENT_VIEW_REQUEST";
export const CLIENT_VIEW_SUCCESS = "CLIENT_VIEW_SUCCESS";
export const CLIENT_VIEW_FAIL = "CLIENT_VIEW_FAIL";

export const CLIENT_CREATE_REQUEST = "CLIENT_CREATE_REQUEST";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_FAIL = "CLIENT_CREATE_FAIL";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";
export const CLIENT_OR_USER_DELETE_REQUEST = "CLIENT_OR_USER_DELETE_REQUEST";
export const CLIENT_OR_USER_DELETE_SUCCESS = "CLIENT_OR_USER_DELETE_SUCCESS";
export const CLIENT_OR_USER_DELETE_FAIL = "CLIENT_OR_USER_DELETE_FAIL";

export const PASSENGER_VIEW_REQUEST = "PASSENGER_VIEW_REQUEST";
export const PASSENGER_VIEW_SUCCESS = "PASSENGER_VIEW_SUCCESS";
export const PASSENGER_VIEW_FAIL = "PASSENGER_VIEW_FAIL";
export const PASSENGER_CREATE_REQUEST = "PASSENGER_CREATE_REQUEST";
export const PASSENGER_CREATE_SUCCESS = "PASSENGER_CREATE_SUCCESS";
export const PASSENGER_CREATE_FAIL = "PASSENGER_CREATE_FAIL";
export const PASSENGER_UPDATE_REQUEST = "PASSENGER_UPDATE_REQUEST";
export const PASSENGER_UPDATE_SUCCESS = "PASSENGER_UPDATE_SUCCESS";
export const PASSENGER_UPDATE_FAIL = "PASSENGER_UPDATE_FAIL";
export const PASSENGER_DELETE_REQUEST = "PASSENGER_DELETE_REQUEST";
export const PASSENGER_DELETE_SUCCESS = "PASSENGER_DELETE_SUCCESS";
export const PASSENGER_DELETE_FAIL = "PASSENGER_DELETE_FAIL";
export const GET_PASSENGER_REQUEST = "GET_PASSENGER_REQUEST";
export const GET_PASSENGER_SUCCESS = "GET_PASSENGER_SUCCESS";
export const GET_PASSENGER_FAIL = "GET_PASSENGER_FAIL";

export const STUDENT_CREATE_REQUEST = "STUDENT_CREATE_REQUEST";
export const STUDENT_CREATE_SUCCESS = "STUDENT_CREATE_SUCCESS";
export const STUDENT_CREATE_FAIL = "STUDENT_CREATE_FAIL";

export const ROUTE_PASSENGER_CREATE_REQUEST = "ROUTE_PASSENGER_CREATE_REQUEST";
export const ROUTE_PASSENGER_CREATE_SUCCESS = "ROUTE_PASSENGER_CREATE_SUCCESS";
export const ROUTE_PASSENGER_CREATE_FAIL = "ROUTE_PASSENGER_CREATE_FAIL";

export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL";

export const GET_ROUTE_PASSENGERS_REQUEST = "GET_ROUTE_PASSENGERS_REQUEST";
export const GET_ROUTE_PASSENGERS_SUCCESS = "GET_ROUTE_PASSENGERS_SUCCESS";
export const GET_ROUTE_PASSENGERS_FAIL = "GET_ROUTE_PASSENGERS_FAIL";

export const ALL_DRIVER_VIEW_REQUEST = "ALL_DRIVER_VIEW_REQUEST";
export const ALL_DRIVER_VIEW_SUCCESS = "ALL_DRIVER_VIEW_SUCCESS";
export const ALL_DRIVER_VIEW_FAIL = "ALL_DRIVER_VIEW_FAIL";

export const ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_REQUEST =
  "ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_REQUEST";
export const ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_SUCCESS =
  "ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_SUCCESS";
export const ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_FAIL =
  "ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_FAIL";

export const GET_VIEW_TRACK_REQUEST = "GET_VIEW_TRACK_REQUEST";
export const GET_VIEW_TRACK_SUCCESS = "GET_VIEW_TRACK_SUCCESS";
export const GET_VIEW_TRACK_FAIL = "GET_VIEW_TRACK_FAIL";

//Company Constants
export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";

export const EXTERNAL_COMPANIES_GET_REQUEST = "EXTERNAL_COMPANIES_GET_REQUEST";
export const EXTERNAL_COMPANIES_GET_SUCCESS = "EXTERNAL_COMPANIES_GET_SUCCESS";
export const EXTERNAL_COMPANIES_GET_FAIL = "EXTERNAL_COMPANIES_GET_FAIL";

export const EXTERNAL_COMPANY_USER_VIEW_REQUEST =
  "EXTERNAL_COMPANY_USER_VIEW_REQUEST";
export const EXTERNAL_COMPANY_USER_VIEW_SUCCESS =
  "EXTERNAL_COMPANY_USER_VIEW_SUCCESS";
export const EXTERNAL_COMPANY_USER_VIEW_FAIL =
  "EXTERNAL_COMPANY_USER_VIEW_FAIL";

export const EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST =
  "EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST";
export const EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS =
  "EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS";
export const EXTERNAL_COMPANY_ROUTES_VIEW_FAIL =
  "EXTERNAL_COMPANY_ROUTES_VIEW_FAIL";

export const EXTERNAL_COMPANY_USER_UPDATE_REQUEST =
  "EXTERNAL_COMPANY_USER_UPDATE_REQUEST";
export const EXTERNAL_COMPANY_USER_UPDATE_SUCCESS =
  "EXTERNAL_COMPANY_USER_UPDATE_SUCCESS";
export const EXTERNAL_COMPANY_USER_UPDATE_FAIL =
  "EXTERNAL_COMPANY_USER_UPDATE_FAIL";
export const EXTERNAL_COMPANY_USER_DELETE_REQUEST =
  "EXTERNAL_COMPANY_USER_DELETE_REQUEST";
export const EXTERNAL_COMPANY_USER_DELETE_SUCCESS =
  "EXTERNAL_COMPANY_USER_DELETE_SUCCESS";
export const EXTERNAL_COMPANY_USER_DELETE_FAIL =
  "EXTERNAL_COMPANY_USER_DELETE_FAIL";

// VEHICLE CONST
export const VEHICLE_VIEW_REQUEST = "VEHICLE_VIEW_REQUEST";
export const VEHICLE_VIEW_SUCCESS = "VEHICLE_VIEW_SUCCESS";
export const VEHICLE_VIEW_FAIL = "VEHICLE_VIEW_FAIL";
export const ALL_BADGES_VIEW_REQUEST = "ALL_BADGES_VIEW_REQUEST";
export const ALL_BADGES_VIEW_SUCCESS = "ALL_BADGES_VIEW_SUCCESS";
export const ALL_BADGES_VIEW_FAIL = "ALL_BADGES_VIEW_FAIL";

export const VEHICLE_CREATE_REQUEST = "VEHICLE_CREATE_REQUEST";
export const VEHICLE_CREATE_SUCCESS = "VEHICLE_CREATE_SUCCESS";
export const VEHICLE_CREATE_FAIL = "VEHICLE_CREATE_FAIL";
export const VEHICLE_UPDATE_REQUEST = "VEHICLE_UPDATE_REQUEST";
export const VEHICLE_UPDATE_SUCCESS = "VEHICLE_UPDATE_SUCCESS";
export const VEHICLE_UPDATE_FAIL = "VEHICLE_UPDATE_FAIL";
export const ALL_VEHICLE_VIEW_REQUEST = "ALL_VEHICLE_VIEW_REQUEST";
export const ALL_VEHICLE_VIEW_SUCCESS = "ALL_VEHICLE_VIEW_SUCCESS";
export const ALL_VEHICLE_VIEW_FAIL = "ALL_VEHICLE_VIEW_FAIL";
export const VEHICLE_DELETE_REQUEST = "VEHICLE_DELETE_REQUEST";
export const VEHICLE_DELETE_SUCCESS = "VEHICLE_DELETE_SUCCESS";
export const VEHICLE_DELETE_FAIL = "VEHICLE_DELETE_FAIL";

// PERMISSION CONST
export const PERMISSION_VIEW_REQUEST = "PERMISSION_VIEW_REQUEST";
export const PERMISSION_VIEW_SUCCESS = "PERMISSION_VIEW_SUCCESS";
export const PERMISSION_VIEW_FAIL = "PERMISSION_VIEW_FAIL";
export const PERMISSION_CREATE_REQUEST = "PERMISSION_CREATE_REQUEST";
export const PERMISSION_CREATE_SUCCESS = "PERMISSION_CREATE_SUCCESS";
export const PERMISSION_CREATE_FAIL = "PERMISSION_CREATE_FAIL";
export const PERMISSION_UPDATE_REQUEST = "PERMISSION_UPDATE_REQUEST";
export const PERMISSION_UPDATE_SUCCESS = "PERMISSION_UPDATE_SUCCESS";
export const PERMISSION_UPDATE_FAIL = "PERMISSION_UPDATE_FAIL";
export const PERMISSION_DELETE_REQUEST = "PERMISSION_DELETE_REQUEST";
export const PERMISSION_DELETE_SUCCESS = "PERMISSION_DELETE_SUCCESS";
export const PERMISSION_DELETE_FAIL = "PERMISSION_DELETE_FAIL";
export const GET_PERMISSION_BY_ID_REQUEST = "GET_PERMISSION_BY_ID_REQUEST";
export const GET_PERMISSION_BY_ID_SUCCESS = "GET_PERMISSION_BY_ID_SUCCESS";
export const GET_PERMISSION_BY_ID_FAIL = "GET_PERMISSION_BY_ID_FAIL";

// LOCATION CONST
export const LOCATION_VIEW_REQUEST = "LOCATION_VIEW_REQUEST";
export const LOCATION_VIEW_SUCCESS = "LOCATION_VIEW_SUCCESS";
export const LOCATION_VIEW_FAIL = "LOCATION_VIEW_FAIL";
export const LOCATION_CREATE_REQUEST = "LOCATION_CREATE_REQUEST";
export const LOCATION_CREATE_SUCCESS = "LOCATION_CREATE_SUCCESS";
export const LOCATION_CREATE_FAIL = "LOCATION_CREATE_FAIL";
export const LOCATION_BY_USER_REQUEST = "LOCATION_BY_USER_REQUEST";
export const LOCATION_BY_USER_SUCCESS = "LOCATION_BY_USER_SUCCESS";
export const LOCATION_BY_USER_FAIL = "LOCATION_BY_USER_FAIL";
export const BASE_LOCATION_REQUEST = "BASE_LOCATION_REQUEST";
export const BASE_LOCATION_SUCCESS = "BASE_LOCATION_SUCCESS";
export const BASE_LOCATION_FAIL = "BASE_LOCATION_FAIL";
export const LOCATION_UPDATE_REQUEST = "LOCATION_UPDATE_REQUEST";
export const LOCATION_UPDATE_SUCCESS = "LOCATION_UPDATE_SUCCESS";
export const LOCATION_UPDATE_FAIL = "LOCATION_UPDATE_FAIL";

// FARE CONST
export const FARE_VIEW_REQUEST = "FARE_VIEW_REQUEST";
export const FARE_VIEW_SUCCESS = "FARE_VIEW_SUCCESS";
export const FARE_VIEW_FAIL = "FARE_VIEW_FAIL";

export const FARE_PRIVATE_VIEW_REQUEST = "FARE_PRIVATE_VIEW_REQUEST";
export const FARE_PRIVATE_VIEW_SUCCESS = "FARE_PRIVATE_VIEW_SUCCESS";
export const FARE_PRIVATE_VIEW_FAIL = "FARE_PRIVATE_VIEW_FAIL";

export const FARE_CLIENT_VIEW_REQUEST = "FARE_CLIENT_VIEW_REQUEST";
export const FARE_CLIENT_VIEW_SUCCESS = "FARE_CLIENT_VIEW_SUCCESS";
export const FARE_CLIENT_VIEW_FAIL = "FARE_CLIENT_VIEW_FAIL";

export const FARE_CREATE_REQUEST = "FARE_CREATE_REQUEST";
export const FARE_CREATE_SUCCESS = "FARE_CREATE_SUCCESS";
export const FARE_CREATE_FAIL = "FARE_CREATE_FAIL";

export const FARE_UPDATE_REQUEST = "FARE_UPDATE_REQUEST";
export const FARE_UPDATE_SUCCESS = "FARE_UPDATE_SUCCESS";
export const FARE_UPDATE_FAIL = "FARE_UPDATE_FAIL";

export const FARE_DELETE_REQUEST = "FARE_DELETE_REQUEST";
export const FARE_DELETE_SUCCESS = "FARE_DELETE_SUCCESS";
export const FARE_DELETE_FAIL = "FARE_DELETE_FAIL";

// RESERVATION CONST
export const RESERVATION_VIEW_REQUEST = "RESERVATION_VIEW_REQUEST";
export const RESERVATION_VIEW_SUCCESS = "RESERVATION_VIEW_SUCCESS";
export const RESERVATION_VIEW_FAIL = "RESERVATION_VIEW_FAIL";
export const RESERVATION_VIEW_REQUEST_V2 = "RESERVATION_VIEW_REQUEST_V2";
export const RESERVATION_VIEW_SUCCESS_V2 = "RESERVATION_VIEW_SUCCESS_V2";
export const RESERVATION_VIEW_FAIL_V2 = "RESERVATION_VIEW_FAIL_V2";
export const RESERVATION_PAST_VIEW_REQUEST = "RESERVATION_PAST_VIEW_REQUEST";
export const RESERVATION_PAST_VIEW_SUCCESS = "RESERVATION_PAST_VIEW_SUCCESS";
export const RESERVATION_PAST_VIEW_FAIL = "RESERVATION_PAST_VIEW_FAIL";
export const RESERVATION_FUTURE_VIEW_REQUEST =
  "RESERVATION_FUTURE_VIEW_REQUEST";
export const RESERVATION_FUTURE_VIEW_SUCCESS =
  "RESERVATION_FUTURE_VIEW_SUCCESS";
export const RESERVATION_FUTURE_VIEW_FAIL = "RESERVATION_FUTURE_VIEW_FAIL";
export const RESERVATION_TODAY_VIEW_REQUEST = "RESERVATION_TODAY_VIEW_REQUEST";
export const RESERVATION_TODAY_VIEW_SUCCESS = "RESERVATION_TODAY_VIEW_SUCCESS";
export const RESERVATION_TODAY_VIEW_FAIL = "RESERVATION_TODAY_VIEW_FAIL";

export const RESERVATION_CREATE_REQUEST = "RESERVATION_CREATE_REQUEST";
export const RESERVATION_CREATE_SUCCESS = "RESERVATION_CREATE_SUCCESS";
export const RESERVATION_CREATE_FAIL = "RESERVATION_CREATE_FAIL";
export const RESERVATION_UPDATE_REQUEST = "RESERVATION_UPDATE_REQUEST";
export const RESERVATION_UPDATE_SUCCESS = "RESERVATION_UPDATE_SUCCESS";
export const RESERVATION_UPDATE_FAIL = "RESERVATION_UPDATE_FAIL";
export const RESERVATION_UPDATE_REQUEST_ST = "RESERVATION_UPDATE_REQUEST_ST";
export const RESERVATION_UPDATE_SUCCESS_ST = "RESERVATION_UPDATE_SUCCESS_ST";
export const RESERVATION_UPDATE_FAIL_ST = "RESERVATION_UPDATE_FAIL_ST";
export const RESERVATION_DELETE_REQUEST = "RESERVATION_DELETE_REQUEST";
export const RESERVATION_DELETE_SUCCESS = "RESERVATION_DELETE_SUCCESS";
export const RESERVATION_DELETE_FAIL = "RESERVATION_DELETE_FAIL";
export const RESERVATION_COMPLETE_DELETE_REQUEST =
  "RESERVATION_COMPLETE_DELETE_REQUEST";
export const RESERVATION_COMPLETE_DELETE_SUCCESS =
  "RESERVATION_COMPLETE_DELETE_SUCCESS";
export const RESERVATION_COMPLETE_DELETE_FAIL =
  "RESERVATION_COMPLETE_DELETE_FAIL";
export const DRIVER_RESERVATION_REQUEST = "DRIVER_RESERVATION_REQUEST";
export const DRIVER_RESERVATION_SUCCESS = "DRIVER_RESERVATION_SUCCESS";
export const DRIVER_RESERVATION_FAIL = "DRIVER_RESERVATION_FAIL";
export const RESERVATION_VIEW_SCHEDULE_REQUEST =
  "RESERVATION_VIEW_SCHEDULE_REQUEST";
export const RESERVATION_VIEW_SCHEDULE_SUCCESS =
  "RESERVATION_VIEW_SCHEDULE_SUCCESS";
export const RESERVATION_VIEW_SCHEDULE_FAIL = "RESERVATION_VIEW_SCHEDULE_FAIL";
export const RESERVATION_VIEW_SCHEDULE_REQUEST_V2 =
  "RESERVATION_VIEW_SCHEDULE_REQUEST_V2";
export const RESERVATION_VIEW_SCHEDULE_SUCCESS_V2 =
  "RESERVATION_VIEW_SCHEDULE_SUCCESS_V2";
export const RESERVATION_VIEW_SCHEDULE_FAIL_V2 =
  "RESERVATION_VIEW_SCHEDULE_FAIL_V2";
export const RESERVATION_DRIVER_STATUS_UPDATE_REQUEST =
  "RESERVATION_DRIVER_STATUS_UPDATE_REQUEST";
export const RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS =
  "RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS";
export const RESERVATION_DRIVER_STATUS_UPDATE_FAIL =
  "RESERVATION_DRIVER_STATUS_UPDATE_FAIL";
export const DRIVER_TODAY_RESERVATION_VIEW_REQUEST =
  "DRIVER_TODAY_RESERVATION_VIEW_REQUEST";
export const DRIVER_TODAY_RESERVATION_VIEW_SUCCESS =
  "DRIVER_TODAY_RESERVATION_VIEW_SUCCESS";
export const DRIVER_TODAY_RESERVATION_VIEW_FAIL =
  "DRIVER_TODAY_RESERVATION_VIEW_FAIL";
export const DRIVER_RIDE_UPDATE_REQUEST = "DRIVER_RIDE_UPDATE_REQUEST";
export const DRIVER_RIDE_UPDATE_SUCCESS = "DRIVER_RIDE_UPDATE_SUCCESS";
export const DRIVER_RIDE_UPDATE_FAIL = "DRIVER_RIDE_UPDATE_FAIL";
export const DRIVER_PAST_RESERVATION_VIEW_REQUEST =
  "DRIVER_PAST_RESERVATION_VIEW_REQUEST";
export const DRIVER_PAST_RESERVATION_VIEW_SUCCESS =
  "DRIVER_PAST_RESERVATION_VIEW_SUCCESS";
export const DRIVER_PAST_RESERVATION_VIEW_FAIL =
  "DRIVER_PAST_RESERVATION_VIEW_FAIL";
export const RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST =
  "RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST";
export const RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS =
  "RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS";
export const RESERVATION_DRIVER_STATUS_BY_USERID_FAIL =
  "RESERVATION_DRIVER_STATUS_BY_USERID_FAIL";
export const DRIVER_EARNING_REQUEST = "DRIVER_EARNING_REQUEST";
export const DRIVER_EARNING_SUCCESS = "DRIVER_EARNING_SUCCESS";
export const DRIVER_EARNING_FAIL = "DRIVER_EARNING_FAIL";
export const RESERVATION_ORDERS_SEARCH_REQUEST =
  "RESERVATION_ORDERS_SEARCH_REQUEST";
export const RESERVATION_ORDERS_SEARCH_SUCCESS =
  "RESERVATION_ORDERS_SEARCH_SUCCESS";
export const RESERVATION_ORDERS_SEARCH_FAIL = "RESERVATION_ORDERS_SEARCH_FAIL";

export const RESERVATION_ORDERS_SEARCH_REQUEST_V2 =
  "RESERVATION_ORDERS_SEARCH_REQUEST_V2";
export const RESERVATION_ORDERS_SEARCH_SUCCESS_V2 =
  "RESERVATION_ORDERS_SEARCH_SUCCESS_V2";
export const RESERVATION_ORDERS_SEARCH_FAIL_V2 =
  "RESERVATION_ORDERS_SEARCH_FAIL_V2";

export const RESERVATION_REQUEST = "RESERVATION_REQUEST";
export const RESERVATION_SUCCESS = "RESERVATION_SUCCESS";
export const RESERVATION_FAIL = "RESERVATION_FAIL";

// SHIFT CONST
export const SHIFT_VIEW_REQUEST = "SHIFT_VIEW_REQUEST";
export const SHIFT_VIEW_SUCCESS = "SHIFT_VIEW_SUCCESS";
export const SHIFT_VIEW_FAIL = "SHIFT_VIEW_FAIL";
export const SHIFT_CREATE_REQUEST = "SHIFT_CREATE_REQUEST";
export const SHIFT_CREATE_SUCCESS = "SHIFT_CREATE_SUCCESS";
export const SHIFT_CREATE_FAIL = "SHIFT_CREATE_FAIL";

// KPI CONST
export const CARDS_DATA_VIEW_REQUEST = "CARDS_DATA_VIEW_REQUEST";
export const CARDS_DATA_VIEW_SUCCESS = "CARDS_DATA_VIEW_SUCCESS";
export const CARDS_DATA_VIEW_FAIL = "CARDS_DATA_VIEW_FAIL";
export const CARDS_DATA2_VIEW_REQUEST = "CARDS_DATA2_VIEW_REQUEST";
export const CARDS_DATA2_VIEW_SUCCESS = "CARDS_DATA2_VIEW_SUCCESS";
export const CARDS_DATA2_VIEW_FAIL = "CARDS_DATA2_VIEW_FAIL";
export const FACILITY_VIEW_REQUEST = "FACILITY_VIEW_REQUEST";
export const FACILITY_VIEW_SUCCESS = "FACILITY_VIEW_SUCCESS";
export const FACILITY_VIEW_FAIL = "FACILITY_VIEW_FAIL";
export const FACILITY_CREATE_REQUEST = "FACILITY_CREATE_REQUEST";
export const FACILITY_CREATE_SUCCESS = "FACILITY_CREATE_SUCCESS";
export const FACILITY_CREATE_FAIL = "FACILITY_CREATE_FAIL";
export const TRIPS_BY_DRIVER_IN_WEEK_VIEW_REQUEST =
  "TRIPS_BY_DRIVER_IN_WEEK_VIEW_REQUEST";
export const TRIPS_BY_DRIVER_IN_WEEK_VIEW_SUCCESS =
  "TRIPS_BY_DRIVER_IN_WEEK_VIEW_SUCCESS";
export const TRIPS_BY_DRIVER_IN_WEEK_VIEW_FAIL =
  "TRIPS_BY_DRIVER_IN_WEEK_VIEW_FAIL";
export const TODAY_RESERVATION_VIEW_REQUEST = "TODAY_RESERVATION_VIEW_REQUEST";
export const TODAY_RESERVATION_VIEW_SUCCESS = "TODAY_RESERVATION_VIEW_SUCCESS";
export const TODAY_RESERVATION_VIEW_FAIL = "TODAY_RESERVATION_VIEW_FAIL";
export const WEEK_RESERVATION_COUNT_VIEW_REQUEST =
  "WEEK_RESERVATION_COUNT_VIEW_REQUEST";
export const WEEK_RESERVATION_COUNT_VIEW_SUCCESS =
  "WEEK_RESERVATION_COUNT_VIEW_SUCCESS";
export const WEEK_RESERVATION_COUNT_VIEW_FAIL =
  "WEEK_RESERVATION_COUNT_VIEW_FAIL";
export const CUSTOMER_SEARCH_VIEW_REQUEST = "CUSTOMER_SEARCH_VIEW_REQUEST";
export const CUSTOMER_SEARCH_VIEW_SUCCESS = "CUSTOMER_SEARCH_VIEW_SUCCESS";
export const CUSTOMER_SEARCH_VIEW_FAIL = "CUSTOMER_SEARCH_VIEW_FAIL";
export const RESERVATION_REPORT_SEARCH_REQUEST =
  "RESERVATION_REPORT_SEARCH_REQUEST";
export const RESERVATION_REPORT_SEARCH_SUCCESS =
  "RESERVATION_REPORT_SEARCH_SUCCESS";
export const RESERVATION_REPORT_SEARCH_FAIL = "RESERVATION_REPORT_SEARCH_FAIL";
export const ROUTE_REPORT_SEARCH_REQUEST = "ROUTE_REPORT_SEARCH_REQUEST";
export const ROUTE_REPORT_SEARCH_SUCCESS = "ROUTE_REPORT_SEARCH_SUCCESS";
export const ROUTE_REPORT_SEARCH_FAIL = "ROUTE_REPORT_SEARCH_FAIL";
export const GET_ROUTE_DISTANCE_REPORT_REQUEST =
  "GET_ROUTE_DISTANCE_REPORT_REQUEST";
export const GET_ROUTE_DISTANCE_REPORT_SUCCESS =
  "GET_ROUTE_DISTANCE_REPORT_SUCCESS";
export const GET_ROUTE_DISTANCE_REPORT_FAIL = "GET_ROUTE_DISTANCE_REPORT_FAIL";
export const GRAPH_DATA_VIEW_REQUEST = "GRAPH_DATA_VIEW_REQUEST";
export const GRAPH_DATA_VIEW_SUCCESS = "GRAPH_DATA_VIEW_SUCCESS";
export const GRAPH_DATA_VIEW_FAIL = "GRAPH_DATA_VIEW_FAIL";
export const GET_ROUTE_SUMMARY_REPORT_REQUEST =
  "GET_ROUTE_SUMMARY_REPORT_REQUEST";
export const GET_ROUTE_SUMMARY_REPORT_SUCCESS =
  "GET_ROUTE_SUMMARY_REPORT_SUCCESS";
export const GET_ROUTE_SUMMARY_REPORT_FAIL = "GET_ROUTE_SUMMARY_REPORT_FAIL";
export const GET_ROUTE_DAILY_DRIVER_REPORT_REQUEST =
  "GET_ROUTE_DAILY_DRIVER_REPORT_REQUEST";
export const GET_ROUTE_DAILY_DRIVER_REPORT_SUCCESS =
  "GET_ROUTE_DAILY_DRIVER_REPORT_SUCCESS";
export const GET_ROUTE_DAILY_DRIVER_REPORT_FAIL =
  "GET_ROUTE_DAILY_DRIVER_REPORT_FAIL";

// SERVICE CONST
export const SERVICE_VIEW_REQUEST = "SERVICE_VIEW_REQUEST";
export const SERVICE_VIEW_SUCCESS = "SERVICE_VIEW_SUCCESS";
export const SERVICE_VIEW_FAIL = "SERVICE_VIEW_FAIL";
export const SERVICE_UPDATE_REQUEST = "SERVICE_UPDATE_REQUEST";
export const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
export const SERVICE_UPDATE_FAIL = "SERVICE_UPDATE_FAIL";

// ADMIN COMPANY INFO CONST
export const ADMIN_COMPANY_INFO_VIEW_REQUEST =
  "ADMIN_COMPANY_INFO_VIEW_REQUEST";
export const ADMIN_COMPANY_INFO_VIEW_SUCCESS =
  "ADMIN_COMPANY_INFO_VIEW_SUCCESS";
export const ADMIN_COMPANY_INFO_VIEW_FAIL = "ADMIN_COMPANY_INFO_VIEW_FAIL";
export const ADMIN_COMPANY_INFO_CREATE_REQUEST =
  "ADMIN_COMPANY_INFO_CREATE_REQUEST";
export const ADMIN_COMPANY_INFO_CREATE_SUCCESS =
  "ADMIN_COMPANY_INFO_CREATE_SUCCESS";
export const ADMIN_COMPANY_INFO_CREATE_FAIL = "ADMIN_COMPANY_INFO_CREATE_FAIL";
export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL";

// TRIP CONST
export const TRIP_VIEW_REQUEST = "TRIP_VIEW_REQUEST";
export const TRIP_VIEW_SUCCESS = "TRIP_VIEW_SUCCESS";
export const TRIP_VIEW_FAIL = "TRIP_VIEW_FAIL";
export const TRIP_CREATE_REQUEST = "TRIP_CREATE_REQUEST";
export const TRIP_CREATE_SUCCESS = "TRIP_CREATE_SUCCESS";
export const TRIP_CREATE_FAIL = "TRIP_CREATE_FAIL";
export const TRIP_MAP_DATA_REQUEST = "TRIP_MAP_DATA_REQUEST";
export const TRIP_MAP_DATA_SUCCESS = "TRIP_MAP_DATA_SUCCESS";
export const TRIP_MAP_DATA_FAIL = "TRIP_MAP_DATA_FAIL";
export const TRIP_UPDATE_REQUEST = "TRIP_UPDATE_REQUEST";
export const TRIP_UPDATE_SUCCESS = "TRIP_UPDATE_SUCCESS";
export const TRIP_UPDATE_FAIL = "TRIP_UPDATE_FAIL";
export const TRIP_WAY_POINTS_REQUEST = "TRIP_WAY_POINTS_REQUEST";
export const TRIP_WAY_POINTS_SUCCESS = "TRIP_WAY_POINTS_SUCCESS";
export const TRIP_WAY_POINTS_FAIL = "TRIP_WAY_POINTS_FAIL";
export const TRIP_RESET_WAY_POINTS = "TRIP_RESET_WAY_POINTS";

// ROUTE TRIP CONST
export const ROUTETRIP_VIEW_REQUEST = "ROUTETRIP_VIEW_REQUEST";
export const ROUTETRIP_VIEW_SUCCESS = "ROUTETRIP_VIEW_SUCCESS";
export const ROUTETRIP_VIEW_FAIL = "ROUTETRIP_VIEW_FAIL";
export const ROUTETRIP_CREATE_REQUEST = "ROUTETRIP_CREATE_REQUEST";
export const ROUTETRIP_CREATE_SUCCESS = "ROUTETRIP_CREATE_SUCCESS";
export const ROUTETRIP_CREATE_SUCCESS_AM = "ROUTETRIP_CREATE_SUCCESS_AM";
export const ROUTETRIP_CREATE_SUCCESS_PM = "ROUTETRIP_CREATE_SUCCESS_PM";
export const ROUTETRIP_CREATE_SUCCESS_MID = "ROUTETRIP_CREATE_SUCCESS_MID";
export const ROUTETRIP_CREATE_FAIL = "ROUTETRIP_CREATE_FAIL";
export const TRIPFORDATE_CREATE_REQUEST = "TRIPFORDATE_CREATE_REQUEST";
export const TRIPFORDATE_CREATE_SUCCESS_AM = "TRIPFORDATE_CREATE_SUCCESS_AM";
export const TRIPFORDATE_CREATE_SUCCESS_PM = "TRIPFORDATE_CREATE_SUCCESS_PM";
export const TRIPFORDATE_CREATE_SUCCESS_MID = "TRIPFORDATE_CREATE_SUCCESS_MID";
export const TRIPFORDATE_CREATE_FAIL = "TRIPFORDATE_CREATE_FAIL";
export const ROUTETRIP_UPDATE_REQUEST = "ROUTETRIP_UPDATE_REQUEST";
export const ROUTETRIP_UPDATE_SUCCESS = "ROUTETRIP_UPDATE_SUCCESS";
export const ROUTETRIP_UPDATE_FAIL = "ROUTETRIP_UPDATE_FAIL";

// SIDEBAR CONSTANTS
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

//

// INVOICE CONSTANTS
export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL";
export const SEARCHED_SETTLEMENT_UPDATED_SUCCESS =
  "SEARCHED_SETTLEMENT_UPDATED_SUCCESS";

export const GET_INVOICES_REQUEST_V2 = "GET_INVOICES_REQUEST_V2";
export const GET_INVOICES_SUCCESS_V2 = "GET_INVOICES_SUCCESS_V2";
export const GET_INVOICES_FAIL_V2 = "GET_INVOICES_FAIL_V2";

export const GET_SEARCHED_INVOICES_REQUEST = "GET_SEARCHED_INVOICES_REQUEST";
export const GET_SEARCHED_INVOICES_SUCCESS = "GET_SEARCHED_INVOICES_SUCCESS";
export const GET_SEARCHED_INVOICES_FAIL = "GET_SEARCHED_INVOICES_FAIL";

export const VIEW_SETTLEMENT_REQUEST = "VIEW_SETTLEMENT_REQUEST";
export const VIEW_SETTLEMENT_SUCCESS = "VIEW_SETTLEMENT_SUCCESS";
export const VIEW_SETTLEMENT_FAIL = "VIEW_SETTLEMENT_FAIL";
export const VIEW_PAGE_SETTLEMENT_REQUEST = "VIEW_PAGE_SETTLEMENT_REQUEST";
export const VIEW_PAGE_SETTLEMENT_SUCCESS = "VIEW_PAGE_SETTLEMENT_SUCCESS";
export const VIEW_PAGE_SETTLEMENT_FAIL = "VIEW_PAGE_SETTLEMENT_FAIL";
export const UPDATE_SETTLEMENT_REQUEST = "UPDATE_SETTLEMENT_REQUEST";
export const UPDATE_SETTLEMENT_SUCCESS = "UPDATE_SETTLEMENT_SUCCESS";
export const UPDATE_SETTLEMENT_FAIL = "UPDATE_SETTLEMENT_FAIL";
export const DELETE_SETTLEMENT_REQUEST = "DELETE_SETTLEMENT_REQUEST";
export const DELETE_SETTLEMENT_SUCCESS = "DELETE_SETTLEMENT_SUCCESS";
export const DELETE_SETTLEMENT_FAIL = "DELETE_SETTLEMENT_FAIL";
export const SETTLEMENT_SEARCH_VIEW_REQUEST = "SETTLEMENT_SEARCH_VIEW_REQUEST";
export const SETTLEMENT_SEARCH_VIEW_SUCCESS = "SETTLEMENT_SEARCH_VIEW_SUCCESS";
export const SETTLEMENT_SEARCH_VIEW_FAIL = "SETTLEMENT_SEARCH_VIEW__FAIL";
export const CREATE_SETTLEMENT_REQUEST = "CREATE_SETTLEMENT_REQUEST";
export const CREATE_SETTLEMENT_SUCCESS = "CREATE_SETTLEMENT_SUCCESS";
export const CREATE_SETTLEMENT_FAIL = "CREATE_SETTLEMENT_FAIL";

// ROUTE CONSTANTS
export const GET_ROUTES_REQUEST = "GET_ROUTES_REQUEST";
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const GET_ROUTES_FAIL = "GET_ROUTES_FAIL";

export const GET_ROUTES_REQUEST_V2 = "GET_ROUTES_REQUEST_V2";
export const GET_ROUTES_SUCCESS_V2 = "GET_ROUTES_SUCCESS_V2";
export const GET_ROUTES_FAIL_V2 = "GET_ROUTES_FAIL_V2";

export const ROUTE_CREATE_REQUEST = "ROUTE_CREATE_REQUEST";
export const ROUTE_CREATE_SUCCESS = "ROUTE_CREATE_SUCCESS";
export const ROUTE_CREATE_FAIL = "ROUTE_CREATE_FAIL";

export const ROUTE_DETAILS_REQUEST = "ROUTE_DETAILS_REQUEST";
export const ROUTE_DETAILS_SUCCESS = "ROUTE_DETAILS_SUCCESS";
export const ROUTE_DETAILS_FAIL = "ROUTE_DETAILS_FAIL";

export const SELECT_ROUTE = "SELECT_ROUTE";

export const ROUTE_UPDATE_REQUEST = "ROUTE_UPDATE_REQUEST";
export const ROUTE_UPDATE_SUCCESS = "ROUTE_UPDATE_SUCCESS";
export const ROUTE_UPDATE_FAIL = "ROUTE_UPDATE_FAIL";

export const UPDATE_UNASSIGNED_ROUTE_REQUEST =
  "UPDATE_UNASSIGNED_ROUTE_REQUEST";
export const UPDATE_UNASSIGNED_ROUTE_SUCCESS =
  "UPDATE_UNASSIGNED_ROUTE_SUCCESS";
export const UPDATE_UNASSIGNED_ROUTE_FAIL = "UPDATE_UNASSIGNED_ROUTE_FAIL";

export const ROUTE_TODAY_ORDER_VIEW_REQUEST = "ROUTE_TODAY_ORDER_VIEW_REQUEST";
export const ROUTE_TODAY_ORDER_VIEW_SUCCESS = "ROUTE_TODAY_ORDER_VIEW_SUCCESS";
export const ROUTE_TODAY_ORDER_VIEW_FAIL = "ROUTE_TODAY_ORDER_VIEW_FAIL";

export const ROUTE_DRIVER_STATUS_UPDATE_REQUEST =
  "ROUTE_DRIVER_STATUS_UPDATE_REQUEST";
export const ROUTE_DRIVER_STATUS_UPDATE_SUCCESS =
  "ROUTE_DRIVER_STATUS_UPDATE_SUCCESS";
export const ROUTE_DRIVER_STATUS_UPDATE_FAIL =
  "ROUTE_DRIVER_STATUS_UPDATE_FAIL";
export const ROUTE_AND_TRIP_REQUEST = "ROUTE_AND_TRIP_REQUEST";
export const ROUTE_AND_TRIP_SUCCESS = "ROUTE_AND_TRIP_SUCCESS";
export const ROUTE_AND_TRIP_FAIL = "ROUTE_AND_TRIP_FAIL";
export const ROUTE_AND_TRIP_REQUEST_V2 = "ROUTE_AND_TRIP_REQUEST_V2";
export const ROUTE_AND_TRIP_SUCCESS_V2 = "ROUTE_AND_TRIP_SUCCESS_V2";
export const ROUTE_AND_TRIP_FAIL_V2 = "ROUTE_AND_TRIP_FAIL_V2";

export const ROUTE_ACTIVE_ORDER_VIEW_REQUEST =
  "ROUTE_ACTIVE_ORDER_VIEW_REQUEST";
export const ROUTE_ACTIVE_ORDER_VIEW_SUCCESS =
  "ROUTE_ACTIVE_ORDER_VIEW_SUCCESS";
export const ROUTE_ACTIVE_ORDER_VIEW_FAIL = "ROUTE_ACTIVE_ORDER_VIEW_FAIL";

export const ALL_ROUTE_LOG_VIEW_REQUEST = "ALL_ROUTE_LOG_VIEW_REQUEST";
export const ALL_ROUTE_LOG_VIEW_SUCCESS = "ALL_ROUTE_LOG_VIEW_SUCCESS";
export const ALL_ROUTE_LOG_VIEW_FAIL = "ALL_ROUTE_LOG_VIEW_FAIL";

// NOTE CONST
export const NOTE_CREATE_REQUEST = "NOTE_CREATE_REQUEST";
export const NOTE_CREATE_SUCCESS = "NOTE_CREATE_SUCCESS";
export const NOTE_CREATE_FAIL = "NOTE_CREATE_FAIL";
export const NOTE_VIEW_REQUEST = "NOTE_VIEW_REQUEST";
export const NOTE_VIEW_SUCCESS = "NOTE_VIEW_SUCCESS";
export const NOTE_VIEW_FAIL = "NOTE_VIEW_FAIL";

// MAIL DATA CONST
export const MAIL_REQUEST = "MAIL_REQUEST";
export const MAIL_SUCCESS = "MAIL_SUCCESS";
export const MAIL_FAIL = "MAIL_FAIL";
export const MAIL_BY_ID_REQUEST = "MAIL_BY_ID_REQUEST";
export const MAIL_BY_ID_SUCCESS = "MAIL_BY_ID_SUCCESS";
export const MAIL_BY_ID_FAIL = "MAIL_BY_ID_FAIL";
export const MAIL_CREATE_REQUEST = "MAIL_CREATE_REQUEST";
export const MAIL_CREATE_SUCCESS = "MAIL_CREATE_SUCCESS";
export const MAIL_CREATE_FAIL = "MAIL_CREATE_FAIL";
export const UPDATE_MAIL_BY_ID_REQUEST = "UPDATE_MAIL_BY_ID_REQUEST";
export const UPDATE_MAIL_BY_ID_SUCCESS = "UPDATE_MAIL_BY_ID_SUCCESS";
export const UPDATE_MAIL_BY_ID_FAIL = "UPDATE_MAIL_BY_ID_FAIL";
export const UPDATE_MAILSEEN_BY_ID_REQUEST = "UPDATE_MAILSEEN_BY_ID_REQUEST";
export const UPDATE_MAILSEEN_BY_ID_SUCCESS = "UPDATE_MAILSEEN_BY_ID_SUCCESS";
export const UPDATE_MAILSEEN_BY_ID_FAIL = "UPDATE_MAILSEEN_BY_ID_FAIL";

// Chat Constants
export const CHAT_CREATE_REQUEST = "CHAT_CREATE_REQUEST";
export const CHAT_CREATE_SUCCESS = "CHAT_CREATE_SUCCESS";
export const CHAT_CREATE_FAIL = "CHAT_CREATE_FAIL";

export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";

export const CHATS_GET_REQUEST = "CHATS_GET_REQUEST";
export const CHATS_GET_SUCCESS = "CHATS_GET_SUCCESS";
export const CHATS_GET_FAIL = "CHATS_GET_FAIL";

export const READ_CHAT_REQUEST = "READ_CHAT_REQUEST";
export const READ_CHAT_SUCCESS = "READ_CHAT_SUCCESS";
export const READ_CHAT_FAIL = "READ_CHAT_FAIL";

export const READALL_CHAT_REQUEST = "READALL_CHAT_REQUEST";
export const READALL_CHAT_SUCCESS = "READALL_CHAT_SUCCESS";
export const READALL_CHAT_FAIL = "READALL_CHAT_FAIL";

export const CHAT_SELECT_REQUEST = "CHAT_SELECT_REQUEST";
export const CHAT_SELECT_SUCCESS = "CHAT_SELECT_SUCCESS";
export const CHAT_SELECT_FAIL = "CHAT_SELECT_FAIL";
export const MODIFY_SELECT_SUCCESS = "MODIFY_SELECT_SUCCESS";
export const MODIFY_SELECT_ACCEPTED = "MODIFY_SELECT_ACCEPTED";

export const MESSAGE_GET_REQUEST = "MESSAGE_GET_REQUEST";
export const MESSAGE_GET_SUCCESS = "MESSAGE_GET_SUCCESS";
export const MESSAGE_GET_FAIL = "MESSAGE_GET_FAIL";

export const MESSAGE_SEND_REQUEST = "MESSAGE_SEND_REQUEST";
export const MESSAGE_SEND_SUCCESS = "MESSAGE_SEND_SUCCESS";
export const MESSAGE_SEND_FAIL = "MESSAGE_SEND_FAIL";

export const MESSAGE_RECEIVE_SUCCESS = "MESSAGE_RECEIVE_SUCCESS";

export const READ_MESSAGES_REQUEST = "READ_MESSAGES_REQUEST";
export const READ_MESSAGES_SUCCESS = "READ_MESSAGES_SUCCESS";
export const READ_MESSAGES_FAIL = "READ_MESSAGES_FAIL";

export const CALL_SEND_REQUEST = "CALL_SEND_REQUEST";
export const CALL_SEND_SUCCESS = "CALL_SEND_SUCCESS";
export const CALL_SEND_FAIL = "CALL_SEND_FAIL";

export const CUSTOMER_IS_READY_MESSAGE_REQUEST =
  "CUSTOMER_IS_READY_MESSAGE_REQUEST";
export const CUSTOMER_IS_READY_MESSAGE_SUCCESS =
  "CUSTOMER_IS_READY_MESSAGE_SUCCESS";
export const CUSTOMER_IS_READY_MESSAGE_FAIL = "CUSTOMER_IS_READY_MESSAGE_FAIL";

export const POPUP_OFF = "POPUP_OFF";
export const POPUP_ON = "POPUP_ON";

export const CALL_RECEIVED = "CALL_RECEIVED";
export const CALL_DECLINED = "CALL_DECLINED";

export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const NOTIFICATION_RECEIVE_SUCCESS = "NOTIFICATION_RECEIVE_SUCCESS";
export const REMINDER_RECEIVE_SUCCESS = "REMINDER_RECEIVE_SUCCESS";

export const READ_NOTIFICATIONS_REQUEST = "READ_NOTIFICATIONS_REQUEST";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAIL = "READ_NOTIFICATIONS_FAIL";

// CONTACT US CONST
export const GET_CONTACTUS_REQUEST = "GET_CONTACTUS_REQUEST";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_FAIL = "GET_CONTACTUS_FAIL";
export const GET_CONTACTUS_BY_ID_REQUEST = "GET_CONTACTUS_BY_ID_REQUEST";
export const GET_CONTACTUS_BY_ID_SUCCESS = "GET_CONTACTUS_BY_ID_SUCCESS";
export const GET_CONTACTUS_BY_ID_FAIL = "GET_CONTACTUS_BY_ID_FAIL";
export const CREATE_CONTACTUS_MSG_REQUEST = "CREATE_CONTACTUS_MSG_REQUEST";
export const CREATE_CONTACTUS_MSG_SUCCESS = "CREATE_CONTACTUS_MSG_SUCCESS";
export const CREATE_CONTACTUS_MSG_FAIL = "CREATE_CONTACTUS_MSG_FAIL";
