import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routeDailyDrivenReport } from "../../../actions/kpiActions";
import moment from "moment";
// import { routeDailyDrivenReport } from "../../../actions/tripActions";
import shift1 from "../../../assets/images/load.png";
import { SERVERADDRESS } from "../../../constants";
import axios from "axios";
import XLSX from "sheetjs-style";
import { notifyFailure } from "../../toast";

const AdminLoadNoAnalysisReportTable = ({ setExcelData }) => {
  const [displayData, setDisplayData] = useState([]);
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const kpi = useSelector((state) => state.kpi);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    from: "",
    to: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    dispatch(
      routeDailyDrivenReport({
        companyId: auth?.user?.companyId?.id,
        from: values.from,
        to: values.to,
      })
    );
  };
  const formatDate = (value) => {
    const firstSplit = value?.split("-route:")[0];
    const secondSplit = firstSplit?.split(":")[1];
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date(secondSplit).toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    return chicagoDate;
  };

  const handleSelectFile = (e) => {
    const acceptedFiles = e.target.files;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const allowedMimeTypes = [
        "application/vnd.ms-excel", // MIME type for .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for .xlsx files
      ];
      if (allowedMimeTypes.includes(file.type)) {
        // The dropped file has a valid MIME type for Excel files
        setFile(file);
      } else {
        notifyFailure("Invalid file type. Please upload a .xls file.");
      }
    }
  };
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setDisplayData([]);
      const reader = new FileReader();
      reader.onload = async (event) => {
        const binaryData = event.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let dataImport = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        dataImport.shift();
        dataImport = dataImport.filter((subArray) => subArray.length > 0);
        dataImport[0][15] = auth?.user?.companyId?.id;
        dataImport[0][16] = !values?.from
          ? moment().format("YYYY-MM-DD")
          : values?.from;
        dataImport[0][17] = !values?.to
          ? moment().format("YYYY-MM-DD")
          : values?.to;
        const { data } = await axios.post(
          `${SERVERADDRESS}/v1/kpi/loadNumberExistReport`,
          { dataImport: dataImport }
        );
        setLoading(false);
        setDisplayData(data);
        setExcelData(
          data?.map((item) => {
            return {
              "Load Number": item?.loadNumber,
              Status: item?.isExist,
            };
          })
        );
      };
      reader.readAsBinaryString(file);
      // console.log("Handle File Upload =>");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div
        className="d-flex justify-content-center w-100"
        style={{ padding: "5px" }}
      >
        <div className="col-lg-3">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  Select File
                </label>
              </div>
            </div>
            <input
              type="file"
              className="input-group-text  f-14"
              name="file"
              id="file"
              style={{ width: "100%" }}
              autocomplete="off"
              onChange={handleSelectFile}
              // value={file}
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  From
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              value={values.from}
              onChange={handleChange("from")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  To
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              placeholder=""
              id="driver"
              min="0"
              value={values.to}
              onChange={handleChange("to")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-2">
            <br />
            <br />
            <button
              className="btn btn-success btn-block"
              onClick={handleSubmit}
              disabled={loading === true || file === null}
            >
              {loading === true ? "Uploading..." : "Upload"}
            </button>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <table
          className="table border w-100 dataTable no-footer"
          id="leads-table"
          role="grid"
          aria-describedby="leads-table_info"
          style={{ overflowX: "auto" }}
        >
          <thead className="card-header">
            <tr role="row">
              <th
                title="Load #"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Mobile: activate to sort column ascending"
              >
                Load #
              </th>
              <th
                title="Status"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Mobile: activate to sort column ascending"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {displayData?.map((row, i) => (
              <tr key={i} className="odd border">
                <td className="border-right">{row?.loadNumber}</td>
                <td className="border-right">{row?.isExist}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {(!displayData || displayData?.length === 0) && loading !== true && (
          <div className="dash-stats-list" style={{ textAlign: "center" }}>
            <br />
            <br />
            <img
              alt=""
              src={shift1}
              style={{ width: "145px", opacity: "0.5" }}
            />
            <br />
            <p style={{ opacity: "0.5" }}>No Data Found</p>
          </div>
        )}
        <div
          id="leads-table_processing"
          className="dataTables_processing card"
          style={{ display: "none" }}
        >
          Processing...
        </div>
      </div>
    </>
  );
};

export default AdminLoadNoAnalysisReportTable;
