/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsAction,
  readNotificationAction,
} from "../../../actions/notificationActions";
import { CHAT_SELECT_SUCCESS } from "../../../constants";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import defaultImg from "../../../assets/images/profile.jpg";
import groupIcon from "../../../assets/images/undraw_People_re_8spw.png";
import { getReservationById } from "../../../actions/reservationAction";
import { readAllChat } from "../../../actions/chatActions";

const ChatMessageDropDown = () => {
  const notifications = useSelector((state) => state.notifications);
  const auth = useSelector((state) => state.auth);
  let arr = [];
  const dispatch = useDispatch();

  const clickHandler = (notificationId, chat) => {
    const notification = notifications.list.find((x) => x.id == notificationId);
    if (!notification.readBy.find((x) => x.readerId === auth.user.id))
      dispatch(
        readNotificationAction({ notificationId, readerId: auth.user.id })
      );
    dispatch({
      type: CHAT_SELECT_SUCCESS,
      payload: chat,
    });
    if (chat.reservationId) {
      dispatch(getReservationById({ _id: chat.reservationId }));
    }
    // openDropDownMessage();
    // setShowDropDownMessage(false);
  };
  function formatTime(dateTime) {
    const timeNow = new Date();
    const messageTime = new Date(dateTime);
    const secondsDiff = Math.round((timeNow - messageTime) / 1000);
    const hoursDiff = Math.round(secondsDiff / 60 / 60);

    let time = "";
    if (secondsDiff < 60) {
      time = "Just Now";
    } else {
      if (secondsDiff < 600) {
        time = Math.round(secondsDiff / 60) + " mins";
      } else {
        if (hoursDiff > 24) {
          time = moment(messageTime).format("ddd, hh:mm a");
        } else {
          // console.log("messageTIme==>", messageTime);
          time = moment(messageTime).format("hh:mm a");
        }
      }
    }
    return time;
  }

  const limit = 35;

  const formatMessage = (message) => {
    if (!message) return "";

    if (message.length <= limit) {
      return message;
    }

    return message.slice(0, limit - 3) + "...";
  };
  // const formattedMessage = formatMessage(item?.chat?.latestMessage?.message);
  const markedAllRead = () => {
    dispatch(readAllChat(auth.user.id, successCB));
  };
  const successCB = () => {
    dispatch(getNotificationsAction(auth.user.id));
  };
  return (
    <>
      <div
        // className="dropdown-menu dropdown-menu-right notification-dropdown border-0 shadow-lg py-0 bg-additional-grey"
        className="dropdown-menu dropdown-menu-right notification-dropdown shadow-lg py-0 bg-additional-grey"
        tabIndex="0"
      >
        <ul className="dropdown3-menu" role="menu" aria-labelledby="dLabel">
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
            <div className="___class_+?50___">
              <div className="f-14 mb-0 text-dark f-w-500">
                <h4>Messages</h4>
              </div>
            </div>
          </div>
          <li className="divider"></li>
          <ul className="notification-list">
            {notifications.loading ? (
              <div id="loader-wrapper">
                <div id="loader">
                  <div className="loader-ellips">
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                    <span className="loader-ellips__dot"></span>
                  </div>
                </div>
              </div>
            ) : (
              notifications.list.length === 0 && (
                <p className="text-center my-5">
                  You don't have any messages yet!{" "}
                </p>
              )
            )}
            {notifications.list
              // .filter((x) => x.sender.id !== auth.user.id)
              .sort((a, b) => moment(a.dateTime).isBefore(b.dateTime))
              .map((item, i) => {
                // console.log(item.id)
                arr = [];
                return (
                  <li
                    className={`notification-message ${
                      item.readBy.find((x) => x.readerId === auth.user.id)
                        ? ""
                        : "bg-msgs"
                    }`}
                    key={i}
                    style={{ width: "100%" }}
                  >
                    {/* <a > */}
                    <NavLink
                      to={`/chat`}
                      onClick={() => clickHandler(item.id, item.chat)}
                    >
                      <div className="media">
                        {/* <div className="list-left"> */}
                        <span className="avatar">
                          <img
                            className="inline-block"
                            alt="Profile Picture"
                            src={
                              item.chat.isGroup
                                ? groupIcon
                                : item.sender.profilePic
                                ? item.sender.profilePic.fileUrl
                                : defaultImg
                            }
                            style={{
                              objectFit: "fill",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        </span>
                        <div className="media-body">
                          {/* { */}
                          {/* <div className="message-author"> */}
                          <p className="noti-details">
                            <span
                              className="noti-title"
                              style={{ fontWeight: "bold" }}
                            >
                              {item?.chat?.isGroup ? (
                                <>
                                  {/* <b>
                                        {item.sender.firstName} {item.sender.lastName}
                                        </b> */}
                                  New message in <b>{item.chat.chatName}</b>
                                </>
                              ) : (
                                <>
                                  <b>
                                    {item?.sender?.firstName}{" "}
                                    {item?.sender?.lastName}
                                  </b>{" "}
                                  sent you a message
                                </>
                              )}
                            </span>
                            {/* } */}
                          </p>
                          <p className="noti-details">
                            {item?.chat?.isGroup && (
                              <>
                                <b>
                                  {item?.sender?.firstName}{" "}
                                  {item?.sender?.lastName}:{" "}
                                </b>
                              </>
                            )}

                            {formatMessage(item?.chat?.latestMessage?.message)
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                            {/* {item?.chat?.latestMessage?.message} */}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              {formatTime(item?.chat?.latestMessage?.dateTime)}
                            </span>
                          </p>
                          {/* </div> */}
                          {/* <div className="clearfix"></div> */}
                          {/* <span className="message-content">
                                {item.chat.isGroup && (
                                <>
                                    <b>
                                    {item.sender.firstName} {item.sender.lastName}:{" "}
                                    </b>
                                </>
                                )}
                                {item.chat.latestMessage.message}
                            </span> */}
                        </div>
                      </div>
                    </NavLink>
                    {/* </a> */}
                  </li>
                );
              })}
          </ul>
          <li className="divider"></li>
          {/* <div className="notification-footer3"><h4 className="menu-title">View all<i className="glyphicon glyphicon-circle-arrow-right"></i></h4></div> */}
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey">
            <div className="___class_+?50___"></div>
          </div>

          <div
            className="topnav-dropdown-footer bg-white"
            style={{
              display: "block",
              textAlign: "center",
              paddingBottom: "10px",
              paddingTop: "3px",
            }}
          >
            {/* <Link to="/compose-message">New Message</Link> */}
            {/* {" | "} */}
            <Link to="/chat">View all Messages</Link>
            {" | "}
            <Link to="#" onClick={markedAllRead}>
              Mark all as Read
            </Link>
          </div>
        </ul>
      </div>
    </>
  );
};
export default ChatMessageDropDown;
