import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routeDailyDrivenReport } from "../../../actions/kpiActions";
import moment from "moment";
// import { routeDailyDrivenReport } from "../../../actions/tripActions";
import shift1 from "../../../assets/images/load.png";

const AdminDailyReportTable = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const kpi = useSelector((state) => state.kpi);
  const { loading } = kpi;
  const dispatch = useDispatch();

  // useEffect(() => {
  //   routeDailyDrivenReportData();
  // }, []);

  // const [selectedDate, setSelectedDate] = useState(moment());

  // const { loading } = reservation;

  // const reservationData = async (e) => {
  //   const chicagoDateOptions = {
  //     timeZone: "America/Chicago",
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };

  //   const chicagoDate = new Date().toLocaleDateString(
  //     "en-US",
  //     chicagoDateOptions
  //   );
  //   const parts = chicagoDate.split("/");
  //   const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

  //   setSelectedDate(moment(formattedChicagoDate));
  //   dispatch(
  //     routeDailyDrivenReport({
  //       companyId: auth.user.companyId.id,
  //       today: formattedChicagoDate,
  //     })
  //   );
  // };

  // useEffect(() => {
  //   reservationData();
  // }, []);
  // const handleDate = (event) => {
  //   setSelectedDate(moment(event.target.value));
  //   dispatch(
  //     routeDailyDrivenReport({
  //       companyId: auth.user.companyId.id,
  //       today: moment(event.target.value).format("YYYY-MM-DD"),
  //     })
  //   );
  // };
  // const routeDailyDrivenReportData = async (e) => {
  //   dispatch(
  //     routeDailyDrivenReport({
  //       companyId: auth?.user?.companyId?.id,
  //       // today: "2024-09-18",
  //       today: selectedDate.format("YYYY-MM-DD"),
  //     })
  //   );
  // };
  const [values, setValues] = useState({
    companyId: "",
    from: "",
    to: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    dispatch(routeDailyDrivenReport({
      companyId: auth?.user?.companyId?.id,
      from: values.from,
      to: values.to,
    }));
  };
  const formatDate = (value) => {
    const firstSplit = value?.split("-route:")[0]
    const secondSplit = firstSplit?.split(":")[1]
        const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date(secondSplit).toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    return chicagoDate
  }
  return (
    <>
      {/* <br /> */}
      <div
        className="d-flex justify-content-center w-100"
        style={{ padding: "5px" }}
      >
        {/* <ul className="date-display d-flex align-items-center">
          <button
            className="btn btn-secondary py-0"
            onClick={() => {
              setSelectedDate(moment(selectedDate).subtract(1, "days"));
              dispatch(
                routeDailyDrivenReport({
                  companyId: auth.user.companyId.id,
                  today: moment(selectedDate)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                })
              );
            }}
          >
            <i className="fa fa-angle-left"></i>
          </button>
          <h6 className="pl-3 pr-3 m-0" id="date-info">
            <input
              type="date"
              className="form-control height-35 f-14"
              value={selectedDate.format("YYYY-MM-DD")}
              onChange={handleDate}
            />
          </h6>
          <button
            className="btn btn-secondary py-0"
            onClick={() => {
              setSelectedDate(moment(selectedDate).add(1, "days"));
              dispatch(
                routeDailyDrivenReport({
                  companyId: auth.user.companyId.id,
                  today: moment(selectedDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                })
              );
            }}
          >
            <i className="fa fa-angle-right"></i>
          </button>
        </ul> */}
        <div className="col-lg-3">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  From
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              value={values.from}
              onChange={handleChange("from")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  To
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              placeholder=""
              id="driver"
              min="0"
              value={values.to}
              onChange={handleChange("to")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-2">
            <br />
            <br />
            <button className="btn btn-success btn-block" 
            onClick={handleClick}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <table
          className="table border w-100 dataTable no-footer"
          id="leads-table"
          role="grid"
          aria-describedby="leads-table_info"
          style={{ overflowX: "auto" }}
        >
          <thead className="card-header">
            <tr role="row">
              <th
                title="Route No"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Mobile: activate to sort column ascending"
              >
                Route No
              </th>
              <th
                title="Date"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Mobile: activate to sort column ascending"
              >
                Date
              </th>
              <th
                title="Shift Time"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Mobile: activate to sort column ascending"
              >
                Shift Time
              </th>
              <th
                title="Address Path"
                className="sorting_disabled border-right"
                rowspan="1"
                colspan="1"
                aria-label="Next Follow Up"
              >
                Address Path
              </th>
              <th
                title="Total Distance"
                className="sorting border-right"
                tabIndex="0"
                aria-controls="leads-table"
                rowspan="1"
                colspan="1"
                aria-label="Lead Agent: activate to sort column ascending"
              >
                Total Distance
              </th>
            </tr>
          </thead>
          <tbody>
            {kpi?.routeDailyDrivenReport?.map((row, i) => (
              <tr key={i} className="odd border">
                <td className="border-right">{row.routeNumber}</td>
                <td className="border-right">{formatDate(row?.trip?.routeDateIdentifier)}</td>
                <td className="border-right">{row.shiftTime}</td>
                {/* <td className="border-right">{row.location[array]}</td> */}
                <td className="border-right">
                  {row?.msg
                    ? row?.msg
                    : row?.route?.map((x, index) => (
                        <span key={index}>
                          {x.location}
                          {x.distance && (
                            <>
                              {" - " + x.distance + " "}
                              <span
                                style={{
                                  display: "inline-block",
                                  transform: "rotate(0deg)",
                                }}
                              >
                                &rarr;
                              </span>
                            </>
                          )}
                          <br />
                        </span>
                      ))}
                </td>
                <td className="border-right">
                  {row?.distance}
                  {/* <br /> */}
                  {/* {row?.eta} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {(!kpi?.routeDailyDrivenReport || kpi?.routeDailyDrivenReport?.length === 0) && (
              <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                  alt=""
                  src={shift1}
                  style={{ width: "145px", opacity: "0.5" }}
                />
                <br />
                <p style={{ opacity: "0.5" }}>No Data Found</p>
              </div>
            )}
        <div
          id="leads-table_processing"
          className="dataTables_processing card"
          style={{ display: "none" }}
        >
          Processing...
        </div>
      </div>
    </>
  );
};

export default AdminDailyReportTable;
